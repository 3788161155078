import { FaRegUser } from 'react-icons/fa'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Link } from 'react-router-dom'
import './profiling.css'
import { getAuthProvider } from '../../../../utils/utils'

export const GetStarted = () => {

    const { parsedAuthData: providerDetails } = getAuthProvider()

    return (
        <ProfileWrapper>
            <div className='user-profile-card mb-3'>
                <span>{providerDetails?.email}</span>
                <FaRegUser size={12} className='text-black ms-1' />
            </div>
            <div className='text-center'>
                <h5 className='welcome-heading line-height-125'>Hey {providerDetails?.firstName}, <br /> Welcome to WorkRobin</h5>
                <p className='fs-6 line-height-125 text-black mb-5'>
                    Getting ready for your next offer? Let's get your profile up to date. It only takes 5-6 minutes and you can edit it late.
                    We'll save as you go.
                </p>
                <Link to='/tax-information' className='custom_button zero_button d-block text-decoration-none line-height-150 w-100'>Get Started</Link>
            </div>
        </ProfileWrapper>
    )
}
