import React, { useState } from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { NotFoundCard } from '../../../../components/profile/NotFoundCard'
import { InviteMember } from '../../../../modals/InviteMember'

export const ManageMembers = () => {

    const [showInvite, setShowInvite] = useState(false)

    return (
        <MainDefault containerStyles={styles.container}>
            <h4 className='mb-0 fw-bold' style={styles.topHead}>Manage Members</h4>
            <NotFoundCard
                title='No member added'
                subTitle='You can invite members here'
                buttonText='Invite member'
                onClick={() => setShowInvite(true)}
            />
            <InviteMember
                show={showInvite}
                handleClose={() => setShowInvite(false)}
            />
        </MainDefault>
    )
}

const styles = {
    container: {
        backgroundColor: '#FAFAFA'
    },
    topHead: {
        lineHeight: '120%',
        color: '#25324B'
    }
}