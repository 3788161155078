import { useState } from 'react';

export const useEmailValidation = ({ initialEmail = '', requiredMessage, invalidEmailMessage } = {}) => {

    const [email, setEmail] = useState(initialEmail);
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!value) {
            setError(requiredMessage || 'Email address is required');
            return;
        }

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setError('Please enter a valid email address');
            return;
        }

        setError(''); // Reset error message on every change
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setError('');

        if (!email) return setError(requiredMessage || 'Email address is required');

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(email)) return setError(invalidEmailMessage || 'Please enter a valid email address');

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());

        // Return form data to the calling function
        return { data, email };
    }

    const isEmailValid = email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    return { email, error, isEmailValid, handleEmailChange, handleSubmit }
}
