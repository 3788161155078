import React from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { NotFoundCard } from '../../../../components/profile/NotFoundCard'

export const RolesAndPermissions = () => {
    return (
        <MainDefault containerStyles={styles.container}>
            <h4 className='mb-0 fw-bold' style={styles.topHead}>Roles and Permissions</h4>
            <NotFoundCard
                title='No role added'
                subTitle='You can created your desired role'
                buttonText='Create role'
            />
        </MainDefault>
    )
}

const styles = {
    container: {
        backgroundColor: '#FAFAFA'
    },
    topHead: {
        lineHeight: '120%',
        color: '#25324B'
    }
}