import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/common/button/Button'
import { Input } from '../../../../components/common/input/Input'
import { useEffect, useState } from 'react'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProviderDetails } from '../../../../store/provider/provider.action'

const INFO = {
    title: <>Tell the world about <br /> yourself with your bio</>,
    description: 'Help people to get to know you at a glance'
}

export const BioInputSection = () => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const { email } = location.state || {}

    const [bio, setBio] = useState('')
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()

        setError('')

        const bioInput = e.target.elements.bio.value
        if (!bioInput || bioInput === '') return setError('Bio is required')

        try {
            await PostRequest('/provider/profile/update', { bio }, { showLoader: 'bio-submit' })
            navigate('/submit', { state: location?.state })
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        if(providerDetails) {
            setBio(providerDetails?.bio || '')
        }
    }, [providerDetails])

    useEffect(() => {
        dispatch(fetchProviderDetails())
    }, [])

    return (
        <ProfileWrapper isProvider email={email} info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Input
                    multiline
                    label='Your Bio'
                    placeholder='Your Bio'
                    name='bio'
                    htmlFor='bio'
                    id='bio'
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    error={error}
                    rows={4}
                    className='mb-3'
                />
                <Stack direction='horizontal' gap={4}>
                    <Link
                        to='/skills'
                        className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony text-center py-3'
                    >
                        Back
                    </Link>
                    <Button type='submit' id='bio-submit' disabled={!bio || !!error} className='w-100 py-3'>Continue</Button>
                </Stack>
            </Form>
        </ProfileWrapper>
    )
}
