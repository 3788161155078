import { Col, Form, Row } from 'react-bootstrap'
import { AuthWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/common/button/Button'
import { useState } from 'react'
import { getAuthBuyer, showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'

export const AccountSetup = () => {
    const businessEmail = window.localStorage.getItem('BUYER_BUSINESS_EMAIL') || ''
    const token = window.localStorage.getItem('TOKEN') || ''

    const { authData: authBuyer } = getAuthBuyer()

    const navigate = useNavigate()
    const location = useLocation();

    const email = location?.state || businessEmail

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        businessEmail: email,
        phoneNumber: '',
        password: '',
    })

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        businessEmail: '',
        phoneNumber: '',
        password: '',
    })

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName') {
            if(!value.trim()) {
                error = 'First name is required';
            } else if(!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'First name cannot contains special characters';
            }
        } else if (name === 'lastName') {
            if(!value.trim()) {
                error = 'Last name is required';
            } else if(!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Last name cannot contains special characters';
            }
        } else if (name === 'businessName') {
            if(!value.trim()) {
                error = 'Business name is required';
            } else if(!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Business name cannot contains special characters';
            }
        } else if (name === 'businessEmail') {
            if (!value) {
                error = 'Business Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
        } else if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        } else if (name === 'password') {
            if (!value) {
                error = 'Password is required';
            } else if (value.length < 8) {
                error = 'Your password is not strong enough. Use at least 8 characters';
            } else if (!/[0-9]/.test(value)) {
                error = 'Use at least 1 digit'
            } else if (!/[A-Z]/.test(value)) {
                error = 'Use at least 1 Uppercase letter'
            } else if (!/[a-z]/.test(value)) {
                error = 'Use at least 1 Lowercase letter'
            }
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedValue = name === 'phoneNumber' ? value.replace(/[^0-9]/g, '') : value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            firstName: '',
            lastName: '',
            businessName: '',
            businessEmail: '',
            phoneNumber: '',
            password: '',
        }

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.businessName) newErrors.businessName = 'Business name is required';
        if (!formData.businessEmail) newErrors.businessEmail = 'Business Email is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 8) {
            newErrors.password = 'Your password is not strong enough. Use at least 8 characters';
        } else if (!/[0-9]/.test(formData.password)) {
            newErrors.password = 'Use at least 1 digit'
        } else if (!/[A-Z]/.test(formData.password)) {
            newErrors.password = 'Use at least 1 Uppercase letter'
        } else if (!/[a-z]/.test(formData.password)) {
            newErrors.password = 'Use at least 1 Lowercase letter'
        }

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            try {
                const response = await PostRequest('/buyer/register', formData, { showLoader: 'signup-button' })
                console.log('buyer register response', response)
                navigate('/get-started', { state: response?.firstName })
                if (businessEmail) window.localStorage.removeItem('BUYER_BUSINESS_EMAIL')
                if (authBuyer) window.localStorage.removeItem('AUTH_BUYER')
                if (token) window.localStorage.removeItem('TOKEN')

                window.localStorage.setItem('AUTH_BUYER', JSON.stringify(response))
                window.localStorage.setItem('TOKEN', response?.token)
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value);

    return (
        <AuthWrapper>
            <div className=''>
                <p className='get-started line-height-125 color-grey-500'>GET STARTED</p>
                <h5 className='welcome-heading line-height-125 mb-3'>Welcome to WorkRobin</h5>
            </div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Input
                            label={<>First name <span className='color-primary-500'>*</span></>}
                            name='firstName'
                            id='firstName'
                            htmlFor='firstName'
                            placeholder='Enter your first name'
                            value={formData.firstName}
                            onChange={handleChange}
                            error={errors.firstName}
                            className='mb-2'
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            label={<>Last name <span className='color-primary-500'>*</span></>}
                            name='lastName'
                            id='lastName'
                            htmlFor='lastName'
                            placeholder='Enter your last name'
                            value={formData.lastName}
                            onChange={handleChange}
                            error={errors.lastName}
                            className='mb-2'
                            required
                        />
                    </Col>
                </Row>
                <Input
                    label={<>Business Name <span className='color-primary-500'>*</span></>}
                    name='businessName'
                    id='businessName'
                    htmlFor='businessName'
                    placeholder='Enter your business name'
                    value={formData.businessName}
                    onChange={handleChange}
                    error={errors.businessName}
                    className='mb-2'
                    required
                />
                <Input
                    label={<>Business Email Address <span className='color-primary-500'>*</span></>}
                    name='businessEmail'
                    id='businessEmail'
                    htmlFor='businessEmail'
                    placeholder='example@company.com'
                    type='email'
                    value={formData.businessEmail}
                    onChange={handleChange}
                    error={errors.businessEmail}
                    className='mb-2'
                    required
                    readOnly
                />
                <Input
                    label={<>Phone number <span className='color-primary-500'>*</span></>}
                    name='phoneNumber'
                    id='phoneNumber'
                    htmlFor='phoneNumber'
                    placeholder='Enter your phone number'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    error={errors.phoneNumber}
                    className='mb-2'
                    required
                />
                <Input
                    label={<>Password <span className='color-primary-500'>*</span></>}
                    name='password'
                    id='password'
                    htmlFor='password'
                    placeholder='Enter your password'
                    type='password'
                    value={formData.password}
                    onChange={handleChange}
                    error={errors.password}
                    className='mb-2'
                    required
                />
                <Form.Group className='mb-2'>
                    <Form.Check
                        type='checkbox'
                        required
                        label={
                            <span className='checkbox-label line-height-160 color-primary'>
                                By creating an account means you agree to the {' '}
                                <span className='fw-semibold color-ebony'>Terms & Conditions</span> and our {' '}
                                <span className='fw-semibold color-ebony'>Privacy Policy</span>
                            </span>
                        }
                        name='terms'
                        id='terms-checkbox'
                    />
                </Form.Group>
                <Button type='submit' id='signup-button' disabled={!isFormValid()} className='w-100 py-3 mb-3'>Create Account</Button>
            </Form>
            <div className='login-text line-height-160 color-secondary'>
                Already have an account? {' '}
                <Link to='/login' className='text-decoration-none color-primary'>Login Here</Link>
            </div>
        </AuthWrapper>
    )
}
