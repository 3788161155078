import { MainDefault } from '../../../components/common/Default'
import { Col, ProgressBar, Row, Stack } from 'react-bootstrap'
import { Button } from '../../../components/common/button/Button'
import ReactSelect from 'react-select'
import { dashboardFilterDropdownStyles } from '../../../data/constants'
import { JobUpdates } from '../../../components/dashboard/job-updates/JobUpdates'
import { WorkOrderSummary } from '../../../components/dashboard/analytics-summary/WorkOrderSummary'
import { FiChevronRight } from 'react-icons/fi'
import '../../provider/dashboard/dashboard.css'
import '../../../components/dashboard/analytics-summary/analytics-summary.css'
import { getAuthBuyer } from '../../../utils/utils'

const navItems = [
  { eventKey: 'drafts', label: 'Drafts' },
  { eventKey: 'templates', label: 'Templates' },
  { eventKey: 'published', label: 'Published' },
  { eventKey: 'assigned', label: 'Assigned' },
  { eventKey: 'in-progress', label: 'In-Progress' },
  { eventKey: 'completed', label: 'Completed' },
  { eventKey: 'approved', label: 'Approved' },
  { eventKey: 'paid', label: 'Paid' },
];

const tabPanes = [
  { eventKey: 'drafts', Content: () => <div>Tab content for Drafts</div> },
  { eventKey: 'templates', Content: () => <div>Tab content for Templates</div> },
  { eventKey: 'published', Content: () => <div>Tab content for Published</div> },
  { eventKey: 'assigned', Content: () => <div>Tab content for Assigned</div> },
  { eventKey: 'in-progress', Content: () => <div>Tab content for In-Progress</div> },
  { eventKey: 'completed', Content: () => <div>Tab content for Completed</div> },
  { eventKey: 'approved', Content: () => <div>Tab content for Approved</div> },
  { eventKey: 'paid', Content: () => <div>Tab content for Paid</div> },
]

export const Dashboard = () => {
  
  const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer()
  
  return (
    <MainDefault>
      <Stack direction='horizontal' className='badge__group'>
        <div className='font-14 fw-medium me-2'>Your profile is yet to be updated.</div>
        <span className='font-14 fw-medium'>0%</span>
        <ProgressBar className='ms-2' now={5} />
        <Button type='button' className='py-2 px-3 fw-medium font-14 ms-auto'>Update Profile</Button>
      </Stack>
      <div className='top-nav py-3'>
        <h4 className='mb-0'>Good morning, {parsedAuthBuyer?.firstName}</h4>
      </div>
      <div className='greeting__filter my-4'>
        <p>Here is your job listings statistic report from July 19 - July 25.</p>
        <ReactSelect
          menuPlacement='auto'
          isSearchable={false}
          styles={dashboardFilterDropdownStyles}
        />
      </div>
      <Row className='justify-content-between action'>
        <Col as={Row} md={7} className='gap-4 px-0 justify-content-between'>
          <Col md={5} className='in-progress__work-orders analytics'>
            <h1 className='fw-bold'>76</h1>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fw-medium'>In-Progress Work Orders</h6>
              <FiChevronRight size={24} />
            </div>
          </Col>
          <Col md={5} className='published__work-orders analytics'>
            <h1 className='fw-bold'>3</h1>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fw-medium'>Published Work Order</h6>
              <FiChevronRight size={24} />
            </div>
          </Col>
          <Col md={5} className='schedule analytics'>
            <h1 className='fw-bold'>23</h1>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fw-medium'>Providers in Pool</h6>
              <FiChevronRight size={24} />
            </div>
          </Col>
          <Col md={5} className='new-messages analytics'>
            <h1 className='fw-bold'>10</h1>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fw-medium'>New Messages</h6>
              <FiChevronRight size={24} />
            </div>
          </Col>
        </Col>
        <WorkOrderSummary />
      </Row>
      <JobUpdates
        navItems={navItems}
        tabPanes={tabPanes}
      />
    </MainDefault>
  )
}
