import { Badge, ListGroup } from 'react-bootstrap'
import { Button } from '../../button/Button'
import { ReactComponent as Dollar } from '../../../../assets/images/dollar.svg'
import { FiHome, FiLogOut } from 'react-icons/fi'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../../../config/API_URL'
import { PROFILE_ITEMS } from '../../../../data/constants'
import { FaRegBell } from 'react-icons/fa'
import { MdMailOutline } from 'react-icons/md'
import { NavDropdownWrapper } from './Wrapper'
import { googleLogout } from '@react-oauth/google'

export const ProfileDropdown = () => {
    const navigate = useNavigate()

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    const userType = window.localStorage.getItem('USER_TYPE')

    const handleLogout = () => {
        googleLogout()
        window.localStorage.removeItem('TOKEN')
        navigate('/login')
    }
    return (
        <NavDropdownWrapper
            title={
                providerDetails !== null && providerDetails?.profileImage !== '' ? (
                    <img
                        src={/^(https?|ftp):\/\//.test(providerDetails.profileImage) ? providerDetails.profileImage : `${BASE_URL}/${providerDetails.profileImage}`}
                        alt={providerDetails?.firstName}
                        className='rounded-circle object-fit-cover'
                        style={{ height: 35, width: 35 }}
                    />
                ) : (
                    <UserCircle style={{ height: 35, width: 35 }} />
                )}
            id='profile-dropdown'
            header={<span className='fw-semibold color-fiord font-14'>Account menu</span>}
        >
            <ListGroup.Item as={Link} to={`/profile/${userType === 'provider' ? 'my' : 'company'}`}>
                <FiHome size={16} color='#475367' />
                <span className='ms-2 color-fiord font-14'>{userType === 'provider' ? 'My' : 'Company'} Profile</span>
            </ListGroup.Item>
            {PROFILE_ITEMS.map(item => {
                return <ListGroup.Item as={Link} to={item.to} key={item.to}>
                    <item.icon size={16} color='#475367' style={{ fill: item.to === '/privacy-policy' && '#475367' }} />
                    <span className='ms-2 color-fiord font-14'>{item.label}</span>
                </ListGroup.Item>
            })}
            <ListGroup.Item action onClick={handleLogout}>
                <FiLogOut color='#475367' size={16} />
                <span className='ms-2 color-fiord font-14'>Log out</span>
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}

export const WalletDropdown = () => {
    return (
        <NavDropdownWrapper
            title={
                <div className='p-2 wallet-dropdown__title-wrapper'>
                    <Dollar style={{ marginBottom: 2 }} />
                    <span className='fw-medium font-14'>0.00</span>
                </div>
            }
            id='wallet-dropdown'
            header={<span className='fw-semibold color-fiord font-14'>Account Balance</span>}
        >
            <ListGroup.Item className='d-flex justify-content-between'>
                <div className='p-2'>
                    <Dollar style={{ marginBottom: 6 }} />
                    <span className='fw-semibold fs-5 color-fiord'>0.00</span>
                </div>
                <Button className='py-2' style={{ borderRadius: 12 }}>Withdraw</Button>
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}

export const NotificationDropdown = () => {
    return (
        <NavDropdownWrapper
            title={<FaRegBell size={20} color='#667185' />}
            id='notification-dropdown'
            header={
                <>
                    <p className='fw-semibold color-fiord font-14'>Notification</p>
                    <p className='cursor-pointer color-primary font-12 fw-semibold'>Mark as read</p>
                </>
            }
            headerClasses='d-flex align-items-center justify-content-between'
        >
            <ListGroup.Item className='d-flex align-items-center gap-2'>
                <img src='https://contentwriter.co/images/avatars/1708227404.jpg' className='card-image flex-shrink-0' alt='' />
                <div className='card-content'>
                    <p className='color-grey-500 font-14 mb-1 text-truncate' style={{ maxWidth: 255 }}>Moore Iron submitted an application</p>
                    <p className='color-fiord font-10 mb-1'>2 mins ago</p>
                </div>
            </ListGroup.Item>
            <ListGroup.Item as='button' className='color-primary font-14 fw-medium rounded-bottom'>
                View all notification
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}

export const MessageDropdown = () => {
    return (
        <NavDropdownWrapper
            title={<MdMailOutline size={20} color='#667185' />}
            id='notification-dropdown'
            header={<span className='fw-semibold color-fiord font-14'>Messages</span>}
        >
            <ListGroup.Item className='d-flex align-items-center gap-2'>
                <img src='https://contentwriter.co/images/avatars/1708227404.jpg' className='card-image flex-shrink-0' alt='' />
                <div className='card-content'>
                    <h6 className='font-14 fw-medium color-fiord mb-0'>Moore Iron - <Badge pill className='dropdown__tag'>#12277899</Badge></h6>
                    <p className='color-grey-500 font-12 mb-0 text-truncate' style={{ maxWidth: 260 }}>I would love to considered for project where Moore Iron submitted an application</p>
                    <p className='color-fiord font-10'>Yesterday</p>
                </div>
            </ListGroup.Item>
            <ListGroup.Item as='button' className='color-primary font-14 fw-medium rounded-bottom'>
                View all messages
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}