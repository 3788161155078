import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Button } from '../../../../components/common/button/Button'
import { formatFileSize, showToaster } from '../../../../utils/utils'
import { IoCloseOutline } from 'react-icons/io5'
import { ReactComponent as PDF } from '../../../../assets/images/PDF.svg'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import PostRequest from '../../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchProviderDetails, fetchTaxDetails } from '../../../../store/provider/provider.action'
import { BASE_URL } from '../../../../config/API_URL'
import { getCountries } from 'country-state-picker'
import moment from 'moment/moment'

export const SubmitProfile = () => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    const taxDetails = useSelector(state => state.providerReducer.taxDetails)
    const parsedResume = JSON.parse(window.localStorage.getItem('RESUME')) || {}

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const resume = location.state

    var countries = getCountries()

    countries = countries.map(country => ({
        label: country?.name,
        value: country?.name,
        code: country?.code
    }))
    const authProviderCountry = countries?.find(option => option.code === taxDetails?.country) || null
    
    const currentTime = moment().format('h:mmA') + ' Local Time'
    
    const imageSrc = `${BASE_URL}/${providerDetails?.profileImage}`

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            ...providerDetails,
            isProfileSubmit: true
        }
        
        const options = {
            isMultiMedia: true,
            showLoader: 'profile-submit-button'
        }

        try {
            const response = await PostRequest('/provider/profile/update', data, options)
            console.log('response', response)

            navigate('/dashboard')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        dispatch(fetchProviderDetails())
        dispatch(fetchTaxDetails())
    }, [])

    return (
        <ProfileWrapper>
            <h5 className='welcome-heading line-height-125 text-center mb-3'>Submit your profile</h5>
            <Form onSubmit={handleSubmit}>
                <Stack direction='horizontal' gap={4} className='mb-3'>
                    {imageSrc ? <img
                        src={imageSrc}
                        alt='Uploaded'
                        className='rounded-circle object-fit-cover'
                        style={{ height: 96, width: 96 }}
                    /> : <UserCircle />}
                    <div>
                        <h6 className='profile_label line-height-125 mb-1'>{providerDetails?.firstName} {providerDetails?.lastName}</h6>
                        <p className='mb-1 profile_timezone'>{taxDetails?.state}, {authProviderCountry?.value}</p>
                        <p className='mb-1 profile_timezone'>{currentTime}</p>
                        <Button
                            type='button'
                            // onClick={() => navigate('/photo-location')}
                            variant='outline'
                            className='py-2 font-12 rounded fw-medium border'
                        >
                            Edit
                        </Button>
                    </div>
                </Stack>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>{taxDetails?.tax}</span>
                        <span className='color-primary fw-semibold profile_timezone cursor-pointer'>Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <span className='form-label'>{`*****${taxDetails?.taxNumberLastDigits}`}</span>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>My Bio</span>
                        <span className='color-primary fw-semibold profile_timezone cursor-pointer'>Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <span className='form-label'>
                        {providerDetails?.bio}
                    </span>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>Your skills</span>
                        <span className='color-primary fw-semibold profile_timezone cursor-pointer'>Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className='flex-wrap' gap={2}>
                        {providerDetails?.skills?.map((skill, i) => {
                            return <span className='skill' key={i}>{skill}</span>
                        })}
                    </Stack>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>My resume</span>
                        <span
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            // onClick={() => navigate('/resume-uploader')}
                        >Replace</span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className=' justify-content-between mb-3'>
                        <PDF className='flex-shrink-0' />
                        <div className='me-auto ms-2'>
                            <p className='pdf-title text-truncate' style={{ maxWidth: 200 }}>{resume ? resume.name : parsedResume?.name}</p>
                            <span className='pdf-size'>{formatFileSize(resume ? resume.size : parsedResume?.size)}</span>
                        </div>
                        <div className='pdf-close__wrapper'>
                            <IoCloseOutline size={20} />
                        </div>
                    </Stack>
                </div>
                <Button type='submit' id='profile-submit-button' disabled={false} className='w-100 py-3'>Submit profile</Button>
            </Form>
        </ProfileWrapper>
    )
}
