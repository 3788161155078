import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as Illustration } from '../../../../assets/images/illustration.svg'
import { Link } from 'react-router-dom'

export const ResetPasswordSuccess = () => {
    return (
        <Container>
            <Row className='justify-content-center align-items-center vh-100'>
                <Col xs={11} sm={8} md={4} className='text-center'>
                    <Illustration />
                    <h5 className='welcome-heading mb-4 line-height-125'>Password changed successfully</h5>
                    <Link to='/login' className='custom_button zero_button d-block text-decoration-none line-height-150 w-100 mb-4'>Back to Login</Link>
                </Col>
            </Row>
        </Container>
    )
}
