import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../common/button/Button'
import { ReactComponent as NotFound } from '../../assets/images/not-found.svg'

export const NotFoundCard = ({ title, subTitle, buttonText, onClick }) => {
    return (
        <Row
            className='justify-content-center align-items-center overflow-y-auto'
            style={{ height: 'calc(100vh - 250px)', scrollbarWidth: 'none' }}
        >
            <Col xs={11} sm={8} md={4} style={styles.card} className='bg-white p-3 text-center'>
                <NotFound className='mb-3' />
                <p className='fw-semibold'>{title}</p>
                <p className='mb-3' style={styles.subTitle}>{subTitle}</p>
                <Button onClick={onClick} className='w-100 py-3 rounded'>{buttonText}</Button>
            </Col>
        </Row>
    )
}

const styles = {
    card: {
        border: '0.5px solid #EAEBF0',
        borderRadius: 12
    },
    subTitle: {
        color: '#344054'
    }
}