import { useNavigate } from 'react-router-dom'
import { ProfileWrapper } from '../../../../components/common/Default'
import { TaxInformationForm } from '../../../../components/profile/TaxInformationForm'

const INFO = {
    title: 'Tax Information',
    description: 'Just a few info about you...'
}

export const TaxInformation = () => {
    
    const navigate = useNavigate()
    
    const handleSubmit = (onFormSubmit) => {
        const formSubmitResult = onFormSubmit()
        if(!formSubmitResult) return
        
        navigate('/add-funds')
    }
    
    return (
        <ProfileWrapper info={INFO}>
            <TaxInformationForm linkTo='/get-started' onSubmit={handleSubmit} />
        </ProfileWrapper>
    )
}
