import React from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { TaxInformationForm } from '../../../../components/profile/TaxInformationForm'

export const CompanyTaxInformation = () => {

  const handleSubmit = (onFormSubmit) => {
    const formSubmitResult = onFormSubmit()
    if (!formSubmitResult) return
  }

  return (
    <MainDefault>
      <div className='top-nav py-3 mb-3'>
        <h4 className='mb-0'>Tax Information</h4>
      </div>
      <TaxInformationForm
        linkTo='/dashboard'
        linkText='Cancel'
        buttonText='Update Tax Information'
        wrapperClasses='w-50'
        fromProfile
        onSubmit={handleSubmit}
      />
    </MainDefault>
  )
}
