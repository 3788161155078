import { Row } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'

export const Testimonial = () => {
    return (
        <Row className='hero-card scrollbar-none'>
            <div>
                <Logo className='w-auto' />
            </div>
            <div className='hero-typography'>
                <h1 className='line-height-110'>Elevate your processes with WorkRobin</h1>
                <p className='line-height-145'>WorkRobin helps you automate everything from onboarding to payment so you can grow your business</p>
            </div>
            <div className='dark-card'>
                <p className='line-height-145'>
                    WorkRobin has transformed the way our team approaches design.
                    The sheer range of components and the seamless integration of the design system into our workflow have been game-changers. It's like having a toolkit filled with magic that accelerates our projects without compromising on quality.
                </p>
                <div className='d-flex align-items-center gap-2 mt-3'>
                    <img src='https://contentwriter.co/images/avatars/1708227404.jpg' className='card-image' alt='' />
                    <div className='card-content'>
                        <h3 className='card-title line-height-110 mb-1'>Ariana Grande</h3>
                        <p className='card-description line-height-110'>Plumber, 2024</p>
                    </div>
                </div>
            </div>
        </Row>
    )
}
