import { ProgressBar, Stack } from 'react-bootstrap'
import { MainDefault } from '../../../components/common/Default'
import { Button } from '../../../components/common/button/Button'
import ReactSelect from 'react-select'
import './dashboard.css'
import { dashboardFilterDropdownStyles } from '../../../data/constants'
import { AnalyticsSummary } from '../../../components/dashboard/analytics-summary/AnalyticsSummary'
import { JobUpdates } from '../../../components/dashboard/job-updates/JobUpdates'
import { InvitesContent } from '../../../components/dashboard/job-updates/InvitesContent'
// import { useSelector } from 'react-redux'
// import { useEffect } from 'react'
import { getAuthProvider } from '../../../utils/utils'

const navItems = [
  { eventKey: 'invites', label: 'Invites', badge: 3 },
  { eventKey: 'applied', label: 'Applied' },
  { eventKey: 'counter', label: 'Counter' },
  { eventKey: 'assigned', label: 'Assigned' },
  { eventKey: 'in-progress', label: 'In-Progress' },
  { eventKey: 'completed', label: 'Completed' },
  { eventKey: 'approved', label: 'Approved' },
  { eventKey: 'paid', label: 'Paid' },
];

const tabPanes = [
  { eventKey: 'invites', Content: InvitesContent },
  { eventKey: 'applied', Content: () => <div>Tab content for Applied</div> },
  { eventKey: 'counter', Content: () => <div>Tab content for Counter</div> },
  { eventKey: 'assigned', Content: () => <div>Tab content for Assigned</div> },
  { eventKey: 'in-progress', Content: () => <div>Tab content for In-Progress</div> },
  { eventKey: 'completed', Content: () => <div>Tab content for Completed</div> },
  { eventKey: 'approved', Content: () => <div>Tab content for Approved</div> },
  { eventKey: 'paid', Content: () => <div>Tab content for Paid</div> },
]

export const Dashboard = () => {


  // const user = getLoginUser()
  const { parsedAuthData: parsedAuthProvider } = getAuthProvider()
  // const providerDetails = useSelector(state => state.providerReducer.providerDetails)

  // useEffect(() => {
  //   if(providerDetails) {
  //     window.localStorage.setItem('AUTH_PROVIDER', JSON.stringify(providerDetails))
  //   }
  // }, [providerDetails])
  
  return (
    <MainDefault>
      <Stack direction='horizontal' className='badge__group'>
        <div className='font-14 fw-medium me-2'>Your profile is yet to be updated.</div>
        <span className='font-14 fw-medium'>0%</span>
        <ProgressBar className='ms-2' now={5} />
        <Button type='button' className='py-2 px-3 fw-medium font-14 ms-auto'>Update Profile</Button>
      </Stack>
      <div className='top-nav py-3'>
        <h4 className='mb-0'>Good morning, {parsedAuthProvider?.firstName}</h4>
      </div>
      <div className='greeting__filter my-4'>
        <p>Here is your job listings statistic report from July 19 - July 25.</p>
        <ReactSelect
          menuPlacement='auto'
          isSearchable={false}
          styles={dashboardFilterDropdownStyles}
        />
      </div>
      <AnalyticsSummary />
      <JobUpdates
        navItems={navItems}
        tabPanes={tabPanes}
      />
    </MainDefault>
  )
}
