import PostRequest from '../../services/PostRequest';

export const FETCH_PROVIDER_DETAILS = 'FETCH_PROVIDER_DETAILS'
export const FETCH_TAX_DETAILS = 'FETCH_TAX_DETAILS'
export const FETCH_TITLES = 'FETCH_TITLES'
export const FETCH_SKILLS = 'FETCH_SKILLS'

export const fetchProviderDetails = () => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/provider/profile');
            dispatch({
                type: FETCH_PROVIDER_DETAILS,
                value: response
            })
        } catch (error) {
            console.log('error', error)
        }
    }
}

export const fetchTaxDetails = () => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/provider/tax-details/get');
            dispatch({
                type: FETCH_TAX_DETAILS,
                value: response
            })
        } catch (error) {
            console.log('error', error)
        }
    }
}

export const fetchTitles = () => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/provider/tax-titles');
            dispatch({
                type: FETCH_TITLES,
                value: response
            })
        } catch (error) {
            console.log('error', error)
        }
    }
}

export const fetchSkills = () => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/provider/skills');
            dispatch({
                type: FETCH_SKILLS,
                value: response
            })
        } catch (error) {
            console.log('error', error)
        }
    }
}