import { useState } from 'react'

export const useOTPValidation = () => {
    
    const [otp, setOtp] = useState();
    const [error, setError] = useState();

    const handleSubmit = (e) => {
        e.preventDefault()

        setError('');

        if (!otp) {
            return setError('OTP is required');
        } else if (otp?.length !== 6) {
            return setError('Please enter a valid 6-digit OTP')
        }

        return otp
    }

    const isOTPValid = otp && otp?.length === 6

    return { otp, setOtp, isOTPValid, error, handleSubmit }
}
