import { Form } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Select } from '../../../../components/common/select/Select'
import { employeeOptions } from '../../../../data/custom-options'
import { Button } from '../../../../components/common/button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuthBuyer, showToaster } from '../../../../utils/utils'
import { useEffect, useState } from 'react'
import PostRequest from '../../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBusinessDetails, fetchIndustries } from '../../../../store/buyer/buyer.action'
// import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

export const GetStarted = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer()

    const INFO = {
        title: `Welcome ${location.state || parsedAuthBuyer?.firstName || ''}!`,
        description: 'We\u2019d love to know a bit more about you...'
    }

    const businessDetails = useSelector(state => state.buyerReducer.businessDetails)
    var industryOptions = useSelector(state => state.buyerReducer.industries)
    industryOptions = industryOptions?.map(industry => ({
        label: industry?.name,
        value: industry?._id
    }))

    const [formData, setFormData] = useState({
        address: '',
        industry: null,
        website: '',
        phoneNumber: '',
        totalEmployees: null
    })

    const [errors, setErrors] = useState({
        address: '',
        industry: '',
        website: '',
        phoneNumber: '',
        totalEmployees: ''
    })

    const validateField = (name, value) => {
        var error = '';
        if (name === 'address' && !value) {
            error = 'Address is required';
        } else if (name === 'industry' && !value) {
            error = 'Industry is required';
        } else if (name === 'website' && !value) {
            error = 'Website is required';
        } else if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        } else if (name === 'totalEmployees' && !value) {
            error = 'Number of Employees is required';
        }
        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedValue = name === 'phoneNumber' ? value.replace(/[^0-9]/g, '') : value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    // const handleAddress = ({ description }) => {
    //     geocodeByAddress(description)
    //         .then(results => getLatLng(results[0]))
    //         .then(({ lat, lng }) =>
    //             console.log('Successfully got latitude and longitude', { lat, lng })
    //         )
    //         .catch(error => console.error(error));
    // }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            address: '',
            industry: '',
            website: '',
            phoneNumber: '',
            totalEmployees: ''
        }

        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.industry) newErrors.industry = 'Industry is required';
        if (!formData.website) newErrors.website = 'Website is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        if (!formData.totalEmployees) newErrors.totalEmployees = 'Number of Employees is required';

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            try {
                const data = {
                    ...formData,
                    industry: formData?.industry?.value,
                    totalEmployees: formData?.totalEmployees?.value
                }

                const response = await PostRequest('/buyer/business-details/update', data, { showLoader: 'business-details-button' })
                console.log('buyer business-details response', response)
                navigate('/tax-information')
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if (businessDetails) {
            setFormData({
                address: businessDetails?.address || '',
                industry: industryOptions?.find(option => option.value === businessDetails?.industry) || null,
                website: businessDetails?.website || '',
                phoneNumber: businessDetails?.phoneNumber || '',
                totalEmployees: employeeOptions.find(option => option.value === businessDetails?.totalEmployees) || null
            });
        }
        // eslint-disable-next-line
    }, [businessDetails])

    useEffect(() => {
        dispatch(fetchBusinessDetails())
        dispatch(fetchIndustries())
        // eslint-disable-next-line
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value)

    return (
        <ProfileWrapper info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Input
                    label='Address'
                    placeholder='Address'
                    htmlFor='address'
                    id='address'
                    name='address'
                    value={formData?.address}
                    error={errors?.address}
                    onChange={handleChange}
                    className='mb-2'
                    required
                />
                {/* <GooglePlacesAutocomplete
                    debounce={800}
                    apiKey="AIzaSyBolbxxZRC2pJgG7EUPTJkgaudxRyszZ_U"
                    onSelect={handleAddress}
                    renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                        <div className="suggestions-container">
                            {suggestions.map(suggestion => (
                                <div
                                    className="suggestion"
                                    onClick={event => onSelectSuggestion(suggestion, event)}
                                >
                                    {suggestion.description}
                                </div>
                            ))}
                        </div>
                    )}
                /> */}
                {/* <GooglePlacesAutocomplete
                    apiKey=''
                    selectProps={{
                        value: formData?.address,
                        onChange: handleChange,
                        on
                    }}
                /> */}
                <Select
                    label='Industry'
                    name='industry'
                    inputId='industry'
                    htmlFor='industry'
                    placeholder='Select one'
                    className='mb-2'
                    required
                    options={industryOptions}
                    value={formData?.industry}
                    error={errors?.industry}
                    onChange={(industry) => handleSelectChange(industry, 'industry')}
                />
                <Input
                    label='Website'
                    placeholder='https://www.janedoe.com'
                    htmlFor='website'
                    id='website'
                    name='website'
                    value={formData?.website}
                    error={errors.website}
                    onChange={handleChange}
                    className='mb-2'
                    required
                />
                <Input
                    label='Phone number'
                    name='phoneNumber'
                    id='phoneNumber'
                    htmlFor='phoneNumber'
                    placeholder='01-22222333'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    error={errors.phoneNumber}
                    className='mb-2'
                    required
                />
                <Select
                    label='Number of Employees'
                    name='totalEmployees'
                    inputId='employees'
                    htmlFor='employees'
                    placeholder='Select one'
                    className='mb-2'
                    required
                    options={employeeOptions}
                    value={formData?.totalEmployees}
                    error={errors.totalEmployees}
                    onChange={(employees) => handleSelectChange(employees, 'totalEmployees')}
                />
                <Button
                    type='submit'
                    id='business-details-button'
                    className='w-100 mb-1 py-3'
                    disabled={!isFormValid()}
                >
                    Continue
                </Button>
            </Form>
        </ProfileWrapper>
    )
}
