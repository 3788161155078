import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button } from '../components/common/button/Button'
import { Input } from '../components/common/input/Input'
import { Select } from '../components/common/select/Select'

export const InviteMember = ({ show, handleClose }) => {
    
    const handleInvite = (e) => {
        e.preventDefault()
    }
    
    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Invite Member</h5>
                    <p>You can invite member</p>
                </div>
                <Form onSubmit={handleInvite}>
                    <Input
                        label='Email Address'
                        placeholder='Enter email address'
                        className='mb-2'
                        type='email'
                    />
                    <Select
                        label='Role'
                        placeholder='Select one'
                        className='mb-3'
                        type='email'
                    />
                    <Button className='py-3 w-100'>
                        Invite
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
