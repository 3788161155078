import { ProfileWrapper } from '../../../../components/common/Default'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Select } from '../../../../components/common/select/Select'
import { Button } from '../../../../components/common/button/Button'
import { Input } from '../../../../components/common/input/Input'
import { taxOptions, taxTypeOptions } from '../../../../data/custom-options'
import { useEffect, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { getCountries, getStates } from 'country-state-picker'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxDetails, fetchTitles } from '../../../../store/provider/provider.action'

const INFO = {
    title: 'Tax Information',
    description: 'Getting your account more personalized'
}

export const TaxInformation = () => {

    const taxDetails = useSelector(state => state.providerReducer.taxDetails)
    var titleOptions = useSelector(state => state.providerReducer.titles)
    titleOptions = titleOptions?.map(option => ({
        label: option?.title,
        value: option?.title
    }))

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        tax: null,
        ssn: '',
        ein: '',
        taxType: null,
        title: null,
        address: '',
        country: null,
        state: null,
        city: '',
        street: '',
        zipCode: '',
    })

    const [errors, setErrors] = useState({
        tax: '',
        ssn: '',
        ein: '',
        taxType: '',
        title: '',
        address: '',
        country: '',
        state: '',
        city: '',
        street: '',
        zipCode: ''
    })

    var countries = getCountries()

    countries = countries.map(country => ({
        label: country?.name,
        value: country?.name,
        code: country?.code
    }))

    var states = getStates(formData?.country?.code)
    states = states?.map(state => ({
        label: state,
        value: state
    }))

    const validateField = (name, value) => {
        var error = '';
        if (name === 'tax' && !value) {
            error = 'Tax is required';
        } else if (name === 'taxType' && !value) {
            error = 'Tax Type is required';
        } else if (name === 'address' && !value) {
            error = 'Address is required';
        } else if (name === 'country' && !value) {
            error = 'Country is required';
        } else if (name === 'state' && !value) {
            error = 'State is required';
        } else if (name === 'city' && !value) {
            error = 'City is required';
        } else if (name === 'street' && !value) {
            error = 'Street is required';
        } else if (name === 'zipCode' && !value) {
            error = 'Zip code is required';
        }

        if (name === 'ssn' && !value) {
            error = 'Social Security Number (SSN) is required'
        } else if (name === 'ein' && !value) {
            error = 'Employer Identification Number (EIN) is required'
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        var updatedValue = value;
        if (name === 'ssn' || name === 'ein') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '').slice(0, 9)
        } else if (name === 'zipCode') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '')
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            tax: '',
            ssn: '',
            ein: '',
            taxType: '',
            address: '',
            country: '',
            state: '',
            city: '',
            street: '',
            zipCode: ''
        }

        if (!formData.tax) newErrors.tax = 'Tax is required';
        if (!formData.taxType) newErrors.taxType = 'Tax Type is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.country) newErrors.country = 'Country is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.street) newErrors.street = 'Street is required';
        if (!formData.zipCode) newErrors.zipCode = 'Zip code is required';

        if (formData.tax?.value === 'SSN') {
            if (!formData.ssn) {
                newErrors.ssn = 'Social Security Number (SSN) is required'
            } else if (formData.ssn.trim().length !== 9) {
                newErrors.ssn = 'Social Security Number (SSN) must be 9 digit exactly'
            }
        } else if (formData.tax?.value === 'EIN') {
            if (!formData.ein) {
                newErrors.ein = 'Employer Identification Number (EIN) is required'
            } else if (formData.ein.trim().length !== 9) {
                newErrors.ein = 'Employer Identification Number (EIN) must be 9 digit exactly'
            }
        }

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {

            const data = {
                ...formData,
                tax: formData?.tax?.value,
                taxNumber: formData?.tax?.value === 'SSN' ? formData?.ssn : formData?.ein,
                taxNumberLastDigits: formData?.tax?.value === 'SSN' ? formData?.ssn?.slice(-4) : formData?.ein?.slice(-4),
                taxType: formData?.taxType?.value,
                title: formData?.title?.value,
                country: formData?.country?.code,
                state: formData?.state?.value,
                floor: formData?.address,
            }

            delete data.ssn
            delete data.ein
            delete data.address

            try {
                const response = await PostRequest('/provider/tax-details/update', data, { showLoader: 'tax-details-button' })
                console.log('buyer tax-details response', response)

                navigate('/photo-location')
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if (taxDetails) {
            console.log('states', states)
            console.log('state', states?.find(option => {
                console.log('first', option.value)
                return option.value === taxDetails?.state
            }), taxDetails?.state)
            setFormData({
                tax: taxOptions?.find(option => option.value === taxDetails?.tax) || null,
                ssn: taxDetails?.tax === 'SSN' ? `*****${taxDetails?.taxNumberLastDigits}` : '',
                ein: taxDetails?.tax === 'EIN' ? `*****${taxDetails?.taxNumberLastDigits}` : '',
                taxType: taxTypeOptions?.find(option => option.value === taxDetails?.taxType) || null,
                title: titleOptions?.find(option => option.value === taxDetails?.title) || null,
                country: countries?.find(option => option.code === taxDetails?.country) || null,
                state: states?.find(option => option.value === taxDetails?.state) || null,
                city: taxDetails?.city || '',
                address: taxDetails?.floor || '',
                street: taxDetails?.street || '',
                zipCode: taxDetails?.zipCode || '',
            });
        }
        // eslint-disable-next-line
    }, [taxDetails])

    useEffect(() => {
        dispatch(fetchTitles())
        dispatch(fetchTaxDetails())
        // eslint-disable-next-line
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.keys(formData).every(key => {
        if (key === 'ssn' && formData?.tax?.value === 'EIN') return true;
        if (key === 'ein' && formData?.tax?.value === 'SSN') return true;
        return formData[key];
    })

    const renderTooltip = (props) => (
        <Tooltip {...props}>
            Social Security Number (SSN)/TIN
        </Tooltip>
    )

    return (
        <ProfileWrapper isProvider email info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Select
                    label='Select Tax'
                    name='tax'
                    inputId='tax'
                    htmlFor='tax'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxOptions}
                    value={formData?.tax}
                    onChange={(tax) => handleSelectChange(tax, 'tax')}
                />
                {formData?.tax?.value === 'SSN' ? (
                    <Input
                        label={<>
                            Social Security Number (SSN)/TIN {' '}
                            <OverlayTrigger overlay={renderTooltip}>
                                <span>
                                    <HiInformationCircle
                                        className='color-grey-500'
                                        size={20}
                                    />
                                </span>
                            </OverlayTrigger>
                        </>}
                        placeholder='your ssn'
                        name='ssn'
                        id='ssn'
                        htmlFor='ssn'
                        className='mb-2'
                        value={formData?.ssn}
                        onChange={handleChange}
                        error={errors.ssn}
                        required
                    />
                ) : formData?.tax?.value === 'EIN' ? (
                    <Input
                        label='Employer Identification Number (EIN)'
                        placeholder='EIN Number'
                        name='ein'
                        id='ein'
                        htmlFor='ein'
                        className='mb-2'
                        value={formData?.ein}
                        onChange={handleChange}
                        error={errors.ein}
                        required
                    />
                ) : null}
                <Select
                    label='Select Tax Type'
                    name='taxType'
                    inputId='taxType'
                    htmlFor='taxType'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxTypeOptions}
                    value={formData?.taxType}
                    onChange={(taxType) => handleSelectChange(taxType, 'taxType')}

                />
                <Select
                    label='Title'
                    name='title'
                    htmlFor='title'
                    inputId='title'
                    placeholder='Select one'
                    className='mb-2'
                    options={titleOptions}
                    value={formData?.title}
                    onChange={(title) => handleSelectChange(title, 'title')}
                />
                <Input
                    placeholder='Address'
                    label='Address'
                    name='address'
                    id='address'
                    htmlFor='address'
                    value={formData?.address}
                    onChange={handleChange}
                    className='mb-2'
                />
                <Select
                    name='country'
                    inputId='countrySelect'
                    htmlFor='countrySelect'
                    placeholder='Select country'
                    className='mb-2'
                    isSearchable
                    options={countries}
                    value={formData?.country}
                    onChange={(country) => handleSelectChange(country, 'country')}
                    error={errors?.country}
                />
                <Row>
                    <Col md={6}>
                        <Select
                            name='state'
                            inputId='stateSelect'
                            htmlFor='stateSelect'
                            placeholder='Select state'
                            className='mb-2'
                            options={states}
                            value={formData?.state}
                            onChange={(state) => handleSelectChange(state, 'state')}
                            error={errors?.state}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='Street'
                            name='street'
                            id='street'
                            htmlFor='street'
                            value={formData?.street}
                            onChange={handleChange}
                            className='mb-2'
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='City'
                            name='city'
                            id='city'
                            htmlFor='city'
                            value={formData?.city}
                            onChange={handleChange}
                            className='mb-2'
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='Zip Code'
                            name='zipCode'
                            id='zipCode'
                            htmlFor='zipCode'
                            value={formData?.zipCode}
                            onChange={handleChange}
                            className='mb-2'
                        />
                    </Col>
                </Row>
                <Button
                    type='submit'
                    id='tax-details-button'
                    disabled={!isFormValid()}
                    className='w-100 mb-1 py-3'
                >
                    Continue
                </Button>
            </Form>
        </ProfileWrapper>
    )
}
