import { Route, Routes } from 'react-router-dom';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import { WelcomeToZero } from './pages/provider/authentication/sign-up/WelcomeToZero';
import { AccountSetup } from './pages/provider/authentication/sign-up/AccountSetup';
import { Login } from './pages/provider/authentication/login/Login';
import { IdentityConfirmation } from './pages/provider/authentication/identity-confirmation/IdentityConfirmation';
import { EmailOTPVerification } from './pages/provider/authentication/identity-confirmation/EmailOTPVerification';
import { AuthenticatorCodeVerification } from './pages/provider/authentication/identity-confirmation/AuthenticatorCodeVerification';
import { ForgotPassword } from './pages/provider/authentication/forgot-password/ForgotPassword';
import { ResetPasswordOTP } from './pages/provider/authentication/forgot-password/ResetPasswordOTP';
import { ConfirmationMail } from './pages/provider/authentication/forgot-password/ConfirmationMail';
import { ResetPassword } from './pages/provider/authentication/forgot-password/ResetPassword';
import { ResetPasswordSuccess } from './pages/provider/authentication/forgot-password/ResetPasswordSuccess';
import { GetStarted } from './pages/provider/authentication/profiling/GetStarted';
import { TaxInformation } from './pages/provider/authentication/profiling/TaxInformation';
import { PhotoAndLocation } from './pages/provider/authentication/profiling/PhotoAndLocation';
import { ResumeUploader } from './pages/provider/authentication/profiling/ResumeUploader';
import { SkillsInputSection } from './pages/provider/authentication/profiling/SkillsInputSection';
import { BioInputSection } from './pages/provider/authentication/profiling/BioInputSection';
import { SubmitProfile } from './pages/provider/authentication/profiling/SubmitProfile';
import { Dashboard } from './pages/provider/dashboard/Dashboard';
import { FindWork } from './pages/provider/find-work/FindWork';
import { MyWork } from './pages/provider/my-work/MyWork';
import { Payments } from './pages/provider/payments/Payments';
import { Calendar } from './pages/provider/calendar/Calendar';
import { MyAgreements } from './pages/provider/my-agreements/MyAgreements';
import { Support } from './pages/provider/support/Support';
import { UserType } from './pages/UserType';

import { WelcomeToWorkRobin } from './pages/buyer/authentication/sign-up/WelcomeToWorkRobin';
import { EmailOTPSentMessage } from './pages/buyer/authentication/sign-up/EmailOTPSentMessage';
import { EmailOTPVerification as BuyerEmailOTPVerification } from './pages/buyer/authentication/sign-up/EmailOTPVerification';
import { AccountSetup as BuyerAccountSetup } from './pages/buyer/authentication/sign-up/AccountSetup';
import { GetStarted as BuyerGetStarted } from './pages/buyer/authentication/profiling/GetStarted';
import { TaxInformation as BuyerTaxInformation } from './pages/buyer/authentication/profiling/TaxInformation';
import { AddFunds } from './pages/buyer/authentication/profiling/AddFunds';
import { InviteTeamMembers } from './pages/buyer/authentication/profiling/InviteTeamMembers';
import { PersonOfContact } from './pages/buyer/authentication/profiling/PersonOfContact';
import { Login as BuyerLogin } from './pages/buyer/authentication/login/Login';
import { ForgotPassword as BuyerForgotPassword } from './pages/buyer/authentication/forgot-password/ForgotPassword';
import { ConfirmationMail as BuyerConfirmationMail } from './pages/buyer/authentication/forgot-password/ConfirmationMail';
import { ResetPassword as BuyerResetPassword } from './pages/buyer/authentication/forgot-password/ResetPassword';
import { ResetPasswordOTP as BuyerResetPasswordOTP } from './pages/buyer/authentication/forgot-password/ResetPasswordOTP';
import { ResetPasswordSuccess as BuyerResetPasswordSuccess } from './pages/buyer/authentication/forgot-password/ResetPasswordSuccess';
import { Dashboard as BuyerDashboard } from './pages/buyer/dashboard/Dashboard';
import { Payments as BuyerPayments } from './pages/buyer/payments/Payments';
import { Agreements } from './pages/buyer/agreements/Agreements';
import { Support as BuyerSupport } from './pages/buyer/support/Support';
import { CompanyProfile } from './pages/buyer/profile/general/CompanyProfile';
import ComingSoon from './pages/other/CommingSoon';
import { CompanyTaxInformation } from './pages/buyer/profile/general/CompanyTaxInformation';
import { ManageMembers } from './pages/buyer/profile/user-management/ManageMembers';
import { RolesAndPermissions } from './pages/buyer/profile/company-settings/RolesAndPermissions';
// import { useUserType } from './context/UserTypeContext';

const providerRoutes = <>
  <Route path='/provider/welcome' element={<WelcomeToZero />} />
  <Route path='/account-setup' element={<AccountSetup />} />
  <Route path='/login' element={<Login />} />
  <Route path='/confirm-identity' element={<IdentityConfirmation />} />
  <Route path='/verify-email-otp' element={<EmailOTPVerification />} />
  <Route path='/verify-authenticator-code' element={<AuthenticatorCodeVerification />} />
  <Route path='/forgot-password' element={<ForgotPassword />} />
  <Route path='/reset-password-otp' element={<ResetPasswordOTP />} />
  <Route path='/confirmation-mail' element={<ConfirmationMail />} />
  <Route path='/provider/password/reset/:token' element={<ResetPassword />} />
  <Route path='/reset-password-success' element={<ResetPasswordSuccess />} />
  <Route path='/get-started' element={<GetStarted />} />
  <Route path='/tax-information' element={<TaxInformation />} />
  <Route path='/photo-location' element={<PhotoAndLocation />} />
  <Route path='/resume-uploader' element={<ResumeUploader />} />
  <Route path='/skills' element={<SkillsInputSection />} />
  <Route path='/bio' element={<BioInputSection />} />
  <Route path='/submit' element={<SubmitProfile />} />

  <Route path='/dashboard' element={<Dashboard />} />
  <Route path='/find-work' element={<FindWork />} />
  <Route path='/my-work' element={<MyWork />} />
  <Route path='/payments' element={<Payments />} />
  <Route path='/calendar' element={<Calendar />} />
  <Route path='/my-agreements' element={<MyAgreements />} />
  <Route path='/support' element={<Support />} />


  <Route path='/*' element={<ComingSoon />} />
</>

const buyerRoutes = <>
  <Route path='/buyer/welcome' element={<WelcomeToWorkRobin />} />
  <Route path='/sent-email-otp' element={<EmailOTPSentMessage />} />
  <Route path='/verify-email-otp' element={<BuyerEmailOTPVerification />} />
  <Route path='/account-setup' element={<BuyerAccountSetup />} />
  <Route path='/login' element={<BuyerLogin />} />
  <Route path='/forgot-password' element={<BuyerForgotPassword />} />
  <Route path='/reset-password-otp' element={<BuyerResetPasswordOTP />} />
  <Route path='/confirmation-mail' element={<BuyerConfirmationMail />} />
  <Route path='/buyer/password/reset/:token' element={<BuyerResetPassword />} />
  <Route path='/reset-password-success' element={<BuyerResetPasswordSuccess />} />
  <Route path='/get-started' element={<BuyerGetStarted />} />
  <Route path='/tax-information' element={<BuyerTaxInformation />} />
  <Route path='/add-funds' element={<AddFunds />} />
  <Route path='/invite-team-members' element={<InviteTeamMembers />} />
  <Route path='/person-of-contact' element={<PersonOfContact />} />

  <Route path='/profile/company' element={<CompanyProfile />} />
  <Route path='/profile/tax-information' element={<CompanyTaxInformation />} />
  <Route path='/profile/manage-members' element={<ManageMembers />} />
  <Route path='/profile/roles-permissions' element={<RolesAndPermissions />} />

  <Route path='/dashboard' element={<BuyerDashboard />} />
  {/* <Route path='/find-work' element={<FindWork />} /> */}
  {/* <Route path='/my-work' element={<MyWork />} /> */}
  {/* <Route path='/calendar' element={<Calendar />} /> */}
  {/* <Route path='/calendar' element={<Calendar />} /> */}
  <Route path='/agreements' element={<Agreements />} />
  <Route path='/payments' element={<BuyerPayments />} />
  {/* <Route path='/my-work' element={<MyWork />} /> */}
  {/* <Route path='/calendar' element={<Calendar />} /> */}
  <Route path='/support' element={<BuyerSupport />} />

  <Route path='/*' element={<ComingSoon />} />
</>

export default function App() {

  const userType = window.localStorage.getItem('USER_TYPE')

  return (
    <Routes>
      <Route path='/' element={<UserType />} />
      {userType === 'provider' ? (
        providerRoutes
      ) : userType === 'buyer' ? (
        buyerRoutes
      ) : (
        <Route path='/' element={<UserType />} />
      )}
    </Routes>
  )
}
