export const taxOptions = [
    { label: 'Social Security Number (SSN/TIN)', value: 'SSN' },
    { label: 'Employer Identification Number (EIN)', value: 'EIN' },
]

export const taxTypeOptions = [
    { label: 'Sole proprietorship', value: 'Sole proprietorship' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Limited liability company (LLC)', value: 'Limited liability company (LLC)' },
    { label: 'Corporation', value: 'Corporation' },
]

export const titleOptions = [
    { label: 'Shareholder', value: 'shareholder' },
    { label: 'CEO', value: 'ceo' },
    { label: 'President', value: 'president' },
    { label: 'Treasurer', value: 'treasurer' },
    { label: 'Secretary', value: 'secretary' },
    { label: 'Member', value: 'member' },
    { label: 'Partner', value: 'partner' },
    { label: 'Owner', value: 'owner' },
]

const SKILLS = [
    'JavaScript',
    'React',
    'NodeJs',
    'CSS',
    'HTML',
    'Python',
    'Java',
    'Ruby',
    'C++',
    'TypeScript',
    'Git',
    'Docker',
    'AWS'
];

export const skillsWithLabelValue = SKILLS.map(skill => ({
    label: skill,
    value: skill.toLowerCase().replace(/\s+/g, '_') // or any other transformation you need
}))

export const employeeOptions = [
    { label: '1-5', value: '1-5' },
    { label: '5-20', value: '5-20' },
    { label: '20+', value: '20+' }
]

export const cardTypeOptions = [
    { value: 'Credit', label: 'Credit' },
    { value: 'Debit', label: 'Debit' }
]

export const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: i + 1
}))

const currentYear = new Date().getFullYear();
export const yearOptions = Array.from({ length: 10 }, (_, i) => ({
    value: currentYear + i,
    label: currentYear + i
}))