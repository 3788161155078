// import { Form } from 'react-bootstrap'
import ReactSelect from 'react-select'
import './select.css'
import { selectStyles } from '../../../data/constants';

export const Select = ({ htmlFor, className, label, error, options, newStyles, newControlStyle, ...rest }) => {

    const customStyles = {
        ...selectStyles,
        control: (provided, state) => ({
            ...provided,
            borderRadius: '10px',
            borderColor: state.isFocused || state.menuIsOpen ? '#920AF2' : '#E2E8F0',
            padding: '10px',
            boxShadow: 'none',
            ':hover': {
                borderColor: state.menuIsOpen ? '#920AF2' : '#E2E8F0',
            },
            ...newControlStyle
        }),
    };

    return (
        <div className={className}>
            {label && <label className='form-label line-height-160' htmlFor={htmlFor || ''}>{label}</label>}
            <ReactSelect options={options} menuPlacement='auto' isSearchable={false} styles={{ ...customStyles, ...newStyles }} {...rest} />
            {/* <Form.Select className='form-input' {...rest}>
                <option className='d-none'>Select one</option>
                {options?.map(opt => {
                    return <option value={opt.value} key={opt.value}>{opt.label}</option>
                })}
            </Form.Select> */}
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    )
}
