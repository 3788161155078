import { Col, Form, Row } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/common/button/Button'
import { useEffect, useState } from 'react'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBuyerDetails } from '../../../../store/buyer/buyer.action'

const INFO = {
    title: 'Your Person of Contact',
    description: 'Just a few info about your person of contact...'
}

export const PersonOfContact = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const buyerDetails = useSelector(state => state.buyerReducer.buyerDetails)
    const contactDetails = buyerDetails?.contactDetails

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    })

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    })

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName' && !value) {
            error = 'First name is required';
        } else if (name === 'lastName' && !value) {
            error = 'Last name is required';
        } else if (name === 'email') {
            if (!value) {
                error = 'Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
        } else if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        } else if (name === 'password') {
            if (!value) {
                error = 'Password is required';
            } else if (value.length < 8) {
                error = 'Your password is not strong enough. Use at least 8 characters';
            } else if (!/[0-9]/.test(value)) {
                error = 'Use at least 1 digit'
            } else if (!/[A-Z]/.test(value)) {
                error = 'Use at least 1 Uppercase letter'
            } else if (!/[a-z]/.test(value)) {
                error = 'Use at least 1 Lowercase letter'
            }
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedValue = name === 'phoneNumber' ? value.replace(/[^0-9]/g, '') : value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        }

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            const data = {
                contactDetails: JSON.stringify(formData) || '{}'
            }
            try {
                const response = await PostRequest('/buyer/contact-details/update', data, { showLoader: 'done-button' })
                console.log('buyer contact-details response', response)
                navigate('/dashboard')
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if (contactDetails) {
            setFormData({
                firstName: contactDetails?.firstName || '',
                lastName: contactDetails?.lastName || '',
                email: contactDetails?.email || '',
                phoneNumber: contactDetails?.phoneNumber || '',
            });
        }
        // eslint-disable-next-line
    }, [contactDetails])

    useEffect(() => {
        dispatch(fetchBuyerDetails())
        // eslint-disable-next-line
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value);

    return (
        <ProfileWrapper info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Input
                            label='First name'
                            name='firstName'
                            id='firstName'
                            htmlFor='firstName'
                            placeholder='First name'
                            value={formData.firstName}
                            onChange={handleChange}
                            error={errors.firstName}
                            className='mb-2'
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            label='Last name'
                            name='lastName'
                            id='lastName'
                            htmlFor='lastName'
                            placeholder='Last name'
                            value={formData.lastName}
                            onChange={handleChange}
                            error={errors.lastName}
                            className='mb-2'
                            required
                        />
                    </Col>
                </Row>
                <Input
                    label='Email Address'
                    name='email'
                    id='email'
                    htmlFor='email'
                    placeholder='example@company.com'
                    type='email'
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                    className='mb-2'
                    required
                />
                <Input
                    label='Phone Number'
                    name='phoneNumber'
                    id='phoneNumber'
                    htmlFor='phoneNumber'
                    placeholder='01-22222333'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    error={errors.phoneNumber}
                    className='mb-3'
                    required
                />
                <Row className='mb-1 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Link
                            to='/invite-team-members'
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony py-3 text-center'
                        >
                            Back
                        </Link>
                    </Col>
                    <Col><Button type='submit' id='done-button' disabled={!isFormValid()} className='w-100 py-3'>Done</Button></Col>
                </Row>
            </Form>
        </ProfileWrapper>
    )
}
