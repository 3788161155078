import { Form } from 'react-bootstrap'
import { AuthWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Button } from '../../../../components/common/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useEmailValidation } from '../../../../hooks/useEmailValidation'
import '../../../provider/authentication/sign-up/sign-up.css'
import { showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'

export const WelcomeToWorkRobin = () => {

  const businessEmail = window.localStorage.getItem('BUYER_BUSINESS_EMAIL') || ''

  const navigate = useNavigate()
  const { email, error, isEmailValid, handleEmailChange, handleSubmit } = useEmailValidation({
    initialEmail: businessEmail
  })

  const handleFormSubmit = async (e) => {

    const formResult = handleSubmit(e)

    if(!formResult) return

    const { email: formEmail } = formResult
    if (formEmail) {
      if (businessEmail) {
        window.localStorage.removeItem('BUYER_BUSINESS_EMAIL')
      }
      window.localStorage.setItem('BUYER_BUSINESS_EMAIL', email)

      const data = {
        businessEmail: formEmail
      }

      try {
        const response = await PostRequest('/buyer/register/send-otp/email', data, { showLoader: 'send-mail' })
        console.log('buyer register send-otp email response', response)
        navigate('/sent-email-otp', { state: response?.email || email })
      } catch (error) {
        showToaster(error.message, 'error')
      }
    }
  }

  return (
    <AuthWrapper>
      <div className=''>
        <p className='get-started line-height-125 color-grey-500'>GET STARTED</p>
        <h5 className='welcome-heading line-height-125 mb-3'>Welcome to WorkRobin</h5>
      </div>
      <Form onSubmit={handleFormSubmit}>
        <Input
          label={<>Business Email Address <span className='color-primary-500'>*</span></>}
          name='email'
          id='email'
          htmlFor='email'
          type='email'
          autoComplete='email'
          placeholder='example@company.com'
          value={email}
          onChange={handleEmailChange}
          error={error}
          required
          className='mb-3'
        />
        <Button type='submit' id='send-mail' disabled={!isEmailValid} className='w-100 py-3 mb-3'>Continue</Button>
      </Form>
      <div className='login-text line-height-160 color-secondary'>
        Already have an account? {' '}
        <Link to='/login' className='text-decoration-none color-primary'>Login Here</Link>
      </div>
    </AuthWrapper>
  )
}
