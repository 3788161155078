import { ListGroup } from 'react-bootstrap'
import './sidebar.css'

import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { ReactComponent as Dashboard } from '../../../assets/images/sidebar/elements.svg'

import { BUYER_PROFILE_SIDEBAR_ITEMS, BUYER_SIDEBAR_ITEMS, PROVIDER_SIDEBAR_ITEMS } from '../../../data/constants'
import { Link, useLocation } from 'react-router-dom'
import { HiArrowLongLeft } from 'react-icons/hi2'
import { Fragment } from 'react'

const NavItem = ({ to, icon: Icon, label, paths, isProvider, ...rest }) => {
    const location = useLocation()
    const isActive = location.pathname === to || paths?.includes(location.pathname);

    const stroke = isProvider ? to === '/my-work' : (to === '/work-orders' || to === '/agreements')

    return (
        <Link
            to={to}
            className={`border-0 list-group-item list-group__item ${isActive ? 'list-group__item-active' : 'bg-transparent'}`}
            {...rest}
        >
            {Icon && <Icon
                style={{
                    [stroke || to === '/support' ? 'stroke' : 'fill']: isActive ? '#920AF2' : '#475367',
                    height: 16,
                    width: 16
                }}
            />}
            <span className={`${Icon ? 'ms-2' : ''} ${isActive ? 'color-primary' : 'color-fiord'}`}>{label}</span>
        </Link>
    )
}

export const MainSidebar = () => {

    const userType = window.localStorage.getItem('USER_TYPE')

    const SIDEBAR_ITEMS = userType === 'provider' ? PROVIDER_SIDEBAR_ITEMS : BUYER_SIDEBAR_ITEMS

    return (
        <div className='border-end bg-white sticky-top' id='sidebar-wrapper'>
            <div className='sidebar-heading'>
                <Link to='/dashboard'><Logo /></Link>
            </div>
            {/* <div className='px-2 py-1 mb-3 cursor-pointer w-fit'>
                <HiArrowLongLeft size={22} style={{ marginBottom: 2 }} />
                <span className='fw-medium ms-2'>Back</span>
            </div> */}
            <ListGroup variant='flush'>
                <NavItem icon={Dashboard} label='Dashboard' to='/dashboard' />
                {SIDEBAR_ITEMS.map(item => {
                    return <NavItem key={item.to} isProvider={userType === 'provider'} {...item} />
                })}
            </ListGroup>
        </div>
    )
}

export const ProfileSidebar = () => {
    return <div className='border-end sticky-top scrollbar-thin' id='sidebar-wrapper' style={{ backgroundColor: '#F7F9FC' }}>
        <div className='sidebar-heading'>
            <Link to='/dashboard'><Logo /></Link>
        </div>
        <div className='px-2 py-1 mb-3 w-fit'>
            <Link to='/dashboard'>
                <HiArrowLongLeft size={22} color='black' style={{ marginBottom: 2 }} />
            </Link>
            <span className='fw-medium ms-2'>Back</span>
        </div>
        <ListGroup variant='flush'>
            {BUYER_PROFILE_SIDEBAR_ITEMS.map((section, i) => {
                return <Fragment key={i}>
                    <ListGroup.Item className='border-0 bg-transparent color-grey-900 font-14 fw-semibold'>
                        {section?.title}
                    </ListGroup.Item>
                    {section.items.map((item, index) => (
                        <NavItem key={index} label={item.label} to={item.to} />
                    ))}
                </Fragment>
            })}
        </ListGroup>
    </div>
}