import { Col, Form, Row, Stack } from 'react-bootstrap'
import { MainDefault } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { Button } from '../../../../components/common/button/Button'
import { useRef, useState } from 'react'
import { Select } from '../../../../components/common/select/Select'
import { Link } from 'react-router-dom'

export const CompanyProfile = () => {

    const fileInputRef = useRef(null);

    const [imageSrc, setImageSrc] = useState('')

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result)
            }
            reader.readAsDataURL(file)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <MainDefault>
            <div className='top-nav py-3'>
                <h4 className='mb-0'>Company Profile</h4>
            </div>
            <Form onSubmit={handleSubmit} className='mt-3'>
                <div className='w-50'>
                    <div className='mb-2'>
                        <label className='form-label line-height-160'>Change Logo</label>
                        <div>
                            {imageSrc ? (
                                <img
                                    src={imageSrc}
                                    alt='Uploaded'
                                    className='rounded-circle object-fit-cover'
                                    style={{ height: 96, width: 96 }}
                                />
                            ) : (
                                <UserCircle />
                            )}
                            <Button
                                variant='outline'
                                onClick={(e) => {
                                    e.preventDefault()
                                    fileInputRef.current.click()
                                }}
                                className='py-2 ms-3 font-14 rounded fw-medium'
                            >
                                {imageSrc ? 'Change' : 'Upload'} logo
                            </Button>
                        </div>
                        <input
                            type='file'
                            ref={fileInputRef}
                            name='profileImage'
                            className='d-none'
                            accept='image/png, image/jpeg'
                            onChange={handleFileChange}
                        />
                    </div>
                    <Input label='Business Bio' multiline className='mb-2' />
                    <Input label='Business Email' type='email' placeholder='john.doe@gmail.com' className='mb-2' />
                    <Input label='Phone Number' placeholder='01-22222333' className='mb-2' />
                    <Input label='Address' placeholder='adeleke.tobi@email.com' className='mb-2' />
                    <Select
                        label='Industry'
                        name='industry'
                        inputId='industry'
                        htmlFor='industry'
                        placeholder='Select one'
                        className='mb-2'
                    // value={formData?.selectedTaxType}
                    // onChange={(taxType) => setFormData(prev => ({ ...prev, selectedTaxType: taxType }))}
                    />
                    <Input label='Website' placeholder='https://www.janedoe.com' className='mb-2' />
                    <Select
                        label='Number of Employees'
                        name='employees'
                        inputId='employees'
                        htmlFor='employees'
                        placeholder='Select one'
                        className='mb-2'
                    // value={formData?.selectedTaxType}
                    // onChange={(taxType) => setFormData(prev => ({ ...prev, selectedTaxType: taxType }))}
                    />
                </div>
                <div className='top-nav py-3'>
                    <h6 className='mb-0'>Contact Person</h6>
                </div>
                <div className='w-50 mt-3'>
                    <Row>
                        <Col md={6}>
                            <Input
                                label='First name'
                                name='firstName'
                                id='firstName'
                                htmlFor='firstName'
                                placeholder='First name'
                                // value={formData.firstName}
                                // onChange={handleChange}
                                // error={errors.firstName}
                                className='mb-2'
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label='Last name'
                                name='lastName'
                                id='lastName'
                                htmlFor='lastName'
                                placeholder='Last name'
                                // value={formData.lastName}
                                // onChange={handleChange}
                                // error={errors.lastName}
                                className='mb-2'
                                required
                            />
                        </Col>
                    </Row>
                    <Input
                        label='Email Address'
                        name='email'
                        id='email'
                        htmlFor='email'
                        placeholder='example@company.com'
                        type='email'
                        // value={formData.email}
                        // onChange={handleChange}
                        // error={errors.email}
                        className='mb-2'
                        required
                    />
                    <Input
                        label='Phone Number'
                        name='phoneNumber'
                        id='phoneNumber'
                        htmlFor='phoneNumber'
                        placeholder='01-22222333'
                        // value={formData.email}
                        // onChange={handleChange}
                        // error={errors.email}
                        className='mb-3'
                        required
                    />
                </div>
                <hr className='border-botticelli opacity-100' />
                <Stack direction='horizontal' gap={4}>
                    <Link
                        to='/profile'
                        className='custom_button-outline zero_button d-block text-decoration-none line-height-150 color-ebony border-ebony text-center fw-semibold'
                        style={{ paddingBlock: 14 }}
                    >
                        Cancel
                    </Link>
                    <Button type='submit' className='fw-semibold' style={{ paddingBlock: 14 }}>Update Company Profile</Button>
                </Stack>
            </Form>
        </MainDefault>
    )
}
