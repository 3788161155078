
import { ReactComponent as UserHeart } from '../../../assets/images/user-heart.svg'
import { ReactComponent as Building } from '../../../assets/images/building.svg'
import { HiOutlineCurrencyDollar } from 'react-icons/hi2'
import { FiChevronRight } from 'react-icons/fi'
import { Col, Row, Stack } from 'react-bootstrap'
import './analytics-summary.css'
import { WorkOrderSummary } from './WorkOrderSummary'

export const AnalyticsSummary = () => {
  return (
    <Row className='justify-content-between action'>
        <Col as={Row} md={7} className='row-gap-4 px-0 justify-content-between'>
          <Col md={12} className='mask-group p-3'>
            <Stack direction='horizontal' className='justify-content-between'>
              <div className='analytics'>
                <h6 className='fw-medium'>Analytics</h6>
                <h1 className='fw-semibold'>4.2k <p>+</p></h1>
                <h6 className='fw-semibold'>Overall Impressions</h6>
              </div>
              <div className='my-1'>
                <UserHeart className='mb-1' />
                <p className='mb-2 font-14 fw-medium'>Direct Invitations</p>
                <h3 className='fw-bold line-height-160 mb-0'>100</h3>
              </div>
              <div className='my-1'>
                <Building className='mb-1' fill='white' />
                <p className='mb-2 font-14 fw-medium'>Applied Orders</p>
                <h3 className='fw-bold line-height-160 mb-0'>230</h3>
              </div>
              <div className='my-1'>
                <HiOutlineCurrencyDollar size={28} className='mb-1' />
                <p className='mb-2 font-14 fw-medium'>Earnings Value</p>
                <h3 className='fw-bold line-height-160 mb-0'>$50,000</h3>
              </div>
            </Stack>
          </Col>
          <Col md={5} className='new-candidates analytics'>
            <h1 className='fw-bold'>23</h1>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fw-medium'>Available work orders</h6>
              <FiChevronRight size={24} />
            </div>
          </Col>
          <Col md={5} className='schedule analytics'>
            <h1 className='fw-bold'>10</h1>
            <div className='d-flex justify-content-between align-items-center'>
              <h6 className='fw-medium'>Order Invitations</h6>
              <FiChevronRight size={24} />
            </div>
          </Col>
        </Col>
        <WorkOrderSummary />
      </Row>
  )
}
