import { Col, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap'
import PostRequest from '../../services/PostRequest'
import { showToaster } from '../../utils/utils'
import { Select } from '../common/select/Select'
import { taxOptions, taxTypeOptions } from '../../data/custom-options'
import { Input } from '../common/input/Input'
import { HiInformationCircle } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Button } from '../common/button/Button'
import { useState } from 'react'
import { getCountries, getStates } from 'country-state-picker'

const renderTooltip = (props) => (
    <Tooltip {...props}>
        Social Security Number (SSN)/TIN
    </Tooltip>
)

export const TaxInformationForm = ({
    onSubmit,
    fromProfile = false,
    wrapperClasses,
    linkTo,
    linkText = 'Back',
    buttonText = 'Continue'
}) => {

    const [formData, setFormData] = useState({
        tax: null,
        ssn: '',
        ein: '',
        taxType: null,
        address: '',
        country: null,
        state: null,
        city: '',
        floor: '',
        zipCode: '',
    })

    const [errors, setErrors] = useState({
        tax: '',
        ssn: '',
        ein: '',
        taxType: '',
        address: '',
        country: '',
        state: '',
        city: '',
        floor: '',
        zipCode: ''
    })

    var countries = getCountries()
    countries = countries.map(country => ({
        label: country?.name,
        value: country?.name,
        code: country?.code
    }))

    var states = getStates(formData?.country?.code)
    states = states?.map(state => ({
        label: state,
        value: state
    }))

    const validateField = (name, value) => {
        var error = '';
        if (name === 'tax' && !value) {
            error = 'Tax is required';
        } else if (name === 'taxType' && !value) {
            error = 'Tax Type is required';
        } else if (name === 'address' && !value) {
            error = 'Address is required';
        } else if (name === 'country' && !value) {
            error = 'Country is required';
        } else if (name === 'state' && !value) {
            error = 'State is required';
        } else if (name === 'city' && !value) {
            error = 'City is required';
        } else if (name === 'floor' && !value) {
            error = 'Suite/Floor is required';
        } else if (name === 'zipCode' && !value) {
            error = 'Zip code is required';
        }

        if (name === 'ssn' && !value) {
            error = 'Social Security Number (SSN) is required'
        } else if (name === 'ein' && !value) {
            error = 'Employer Identification Number (EIN) is required'
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        var updatedValue = value;
        if (name === 'ssn' || name === 'ein') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '').slice(0, 9)
        } else if (name === 'zipCode') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '')
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            tax: '',
            ssn: '',
            ein: '',
            taxType: '',
            address: '',
            country: '',
            state: '',
            city: '',
            floor: '',
            zipCode: ''
        }

        if (!formData.tax) newErrors.tax = 'Tax is required';
        if (!formData.taxType) newErrors.taxType = 'Tax Type is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.country) newErrors.country = 'Country is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.floor) newErrors.floor = 'Suite/Floor is required';
        if (!formData.zipCode) newErrors.zipCode = 'Zip code is required';

        if (formData.tax?.value === 'SSN') {
            if (!formData.ssn) {
                newErrors.ssn = 'Social Security Number (SSN) is required'
            } else if (formData.ssn.trim().length !== 9) {
                newErrors.ssn = 'Social Security Number (SSN) must be 9 digit exactly'
            }
        } else if (formData.tax?.value === 'EIN') {
            if (!formData.ein) {
                newErrors.ein = 'Employer Identification Number (EIN) is required'
            } else if (formData.ein.trim().length !== 9) {
                newErrors.ein = 'Employer Identification Number (EIN) must be 9 digit exactly'
            }
        }

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {

            const data = {
                ...formData,
                tax: formData?.tax?.value,
                taxNumber: formData?.tax?.value === 'SSN' ? formData?.ssn : formData?.ein,
                taxNumberLastDigits: formData?.tax?.value === 'SSN' ? formData?.ssn?.slice(-4) : formData?.ein?.slice(-4),
                taxType: formData?.taxType?.value,
                country: formData?.country?.code,
                state: formData?.state?.value,
                street: formData?.address
            }

            delete data.ssn
            delete data.ein
            delete data.address

            try {
                const response = await PostRequest('/buyer/tax-details/update', data, { showLoader: 'tax-details-button' })
                return response
            } catch (error) {
                showToaster(error.message, 'error')
                return error
            }
        }
    }

    const handleSubmit = () => {
        onSubmit(handleFormSubmit)
    }

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.keys(formData).every(key => {
        if (key === 'ssn' && formData?.tax?.value === 'EIN') return true;
        if (key === 'ein' && formData?.tax?.value === 'SSN') return true;
        return formData[key];
    })

    return (
        <Form onSubmit={handleSubmit}>
            <div className={wrapperClasses}>
                <Select
                    label='Tax'
                    name='tax'
                    inputId='tax'
                    htmlFor='tax'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxOptions}
                    value={formData?.tax}
                    error={errors.tax}
                    onChange={(tax) => handleSelectChange(tax, 'tax')}
                    required
                />
                {formData?.tax?.value === 'SSN' ? (
                    <Input
                        label={<>
                            Social Security Number (SSN)/TIN {' '}
                            <OverlayTrigger overlay={renderTooltip}>
                                <span>
                                    <HiInformationCircle
                                        className='color-grey-500'
                                        size={20}
                                    />
                                </span>
                            </OverlayTrigger>
                        </>}
                        placeholder='your ssn'
                        name='ssn'
                        id='ssn'
                        htmlFor='ssn'
                        className='mb-2'
                        value={formData?.ssn}
                        onChange={handleChange}
                        error={errors.ssn}
                        required
                    />
                ) : formData?.tax?.value === 'EIN' ? (
                    <Input
                        label='Employer Identification Number (EIN)'
                        placeholder='EIN Number'
                        name='ein'
                        id='ein'
                        htmlFor='ein'
                        className='mb-2'
                        value={formData?.ein}
                        onChange={handleChange}
                        error={errors.ein}
                        required
                    />
                ) : null}
                <Select
                    label='Tax Type'
                    name='taxType'
                    inputId='taxType'
                    htmlFor='taxType'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxTypeOptions}
                    value={formData?.taxType}
                    error={errors.taxType}
                    onChange={(taxType) => handleSelectChange(taxType, 'taxType')}
                    required
                />
                <Input
                    label='Address'
                    placeholder='Address'
                    htmlFor='address'
                    id='address'
                    name='address'
                    value={formData?.address}
                    error={errors.address}
                    onChange={handleChange}
                    className='mb-2'
                    required
                />
                <Select
                    name='country'
                    inputId='countrySelect'
                    htmlFor='countrySelect'
                    placeholder='Country'
                    className='mb-3'
                    options={countries}
                    value={formData?.country}
                    error={errors.country}
                    isSearchable
                    onChange={(country) => handleSelectChange(country, 'country')}
                    required
                />
                <Row className='row-gap-2 mb-3'>
                    <Col md={6}>
                        <Select
                            name='state'
                            inputId='stateSelect'
                            htmlFor='stateSelect'
                            placeholder='State'
                            className='mb-2'
                            options={states}
                            value={formData?.state}
                            onChange={(state) => handleSelectChange(state, 'state')}
                            error={errors?.state}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='Suite/Floor'
                            name='floor'
                            value={formData?.floor}
                            error={errors.floor}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='City'
                            name='city'
                            value={formData?.city}
                            error={errors.city}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='Zip code'
                            name='zipCode'
                            value={formData?.zipCode}
                            error={errors.zipCode}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                </Row>
            </div>
            {fromProfile ? (
                <>
                    <hr className='border-botticelli opacity-100' />
                    <Stack direction='horizontal' gap={4}>
                        <Link
                            to={linkTo}
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 color-ebony border-ebony text-center fw-semibold'
                            style={{ paddingBlock: 14 }}
                        >
                            {linkText}
                        </Link>
                        <Button
                            type='submit'
                            disabled={!isFormValid()}
                            className='fw-semibold'
                            style={{ paddingBlock: 14 }}
                        >
                            {buttonText}
                        </Button>
                    </Stack>
                </>
            ) : (
                <Row className='mb-1 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Link
                            to={linkTo}
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony py-3 text-center'
                        >
                            {linkText}
                        </Link>
                    </Col>
                    <Col>
                        <Button
                            type='submit'
                            id='tax-details-button'
                            disabled={!isFormValid()}
                            className='w-100 py-3'
                        >
                            {buttonText}
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    )
}
