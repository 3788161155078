import Dropzone from 'react-dropzone'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Form, Stack } from 'react-bootstrap'
import { ReactComponent as Gallery } from '../../../../assets/images/gallery.svg'
import { Button } from '../../../../components/common/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as PDF } from '../../../../assets/images/PDF.svg'
import { formatFileSize, showToaster } from '../../../../utils/utils'
import { IoCloseOutline } from 'react-icons/io5'
import { useEffect, useState } from 'react'
import PostRequest from '../../../../services/PostRequest'
// import { useSelector } from 'react-redux'

const INFO = {
  title: <>How would you like tell us <br /> about yourself</>,
  description: 'To show you where your skills are most in demand, we need to get a sense of your experience, skills and desired tools'
}

export const ResumeUploader = () => {

  const resume = window.localStorage.getItem('RESUME') || ''

  const navigate = useNavigate()
  // const providerDetails = useSelector(state => state.providerReducer.providerDetails)

  const [acceptedFiles, setAcceptedFiles] = useState([])

  const onDrop = (files) => {
    console.log('Accepted Files:', files);
    setAcceptedFiles(files)

    // if (files.length > 0) {
    //   const file = files[0];
    //   console.log('Uploaded Resume:', file);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    var data = {}

    var formData = new FormData(e.target)

    if (acceptedFiles?.length === 0) {
      alert('Please upload a resume PDF file.');
      return;
    }

    formData.append('resume', acceptedFiles[0]);
    formData.forEach((val, key) => data[key] = val)

    if(resume) window.localStorage.removeItem('RESUME')
      
    window.localStorage.setItem('RESUME', JSON.stringify({
      name: data?.resume?.name,
      size: data?.resume?.size
    }))

    try {
      await PostRequest('/provider/profile/update', data, { isMultiMedia: true, showLoader: 'resume-button' })
      navigate('/skills', { state: data?.resume })
    } catch (error) {
      showToaster(error.message, 'error')
    }
  }

  return (
    <ProfileWrapper isProvider email info={INFO}>
      {acceptedFiles?.length === 0 ? (
        <>
          <Dropzone
            maxFiles={1}
            onDrop={onDrop}
            accept={{
              'application/pdf': ['.pdf'],
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Stack gap={2} {...getRootProps({ className: 'dropzone__wrapper cursor-pointer align-items-center mb-3' })}>
                <input {...getInputProps()} />
                <Gallery />
                <span className='text-center text-secondary fw-medium'>
                  drag and drop to upload your resume pdf, doc, docx
                </span>
              </Stack>
            )}
          </Dropzone>
          <Button type='button' disabled={acceptedFiles?.length === 0} className='w-100 py-3'>Upload your resume</Button>
        </>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Stack
            direction='horizontal'
            className='dropzone__wrapper justify-content-between mb-3'
            style={{ border: '1px solid #F0F2F5' }}
          >
            <PDF className='flex-shrink-0' />
            <div className='me-auto ms-2'>
              <p className='pdf-title text-truncate' style={{ maxWidth: 200 }}>{acceptedFiles[0]?.name}</p>
              <span className='pdf-size'>{formatFileSize(acceptedFiles[0]?.size)}</span>
            </div>
            <button className='pdf-close__wrapper border-0' onClick={() => setAcceptedFiles([])}>
              <IoCloseOutline size={20} />
            </button>
          </Stack>
          <Stack direction='horizontal' gap={4}>
            <Link
              to='/photo-location'
              className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony text-center py-3'
            >
              Back
            </Link>
            <Button type='submit' id='resume-button' className='w-100 py-3'>Continue</Button>
          </Stack>
        </Form>
      )}
    </ProfileWrapper>
  )
}
