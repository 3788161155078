// import { ReactComponent as Dashboard } from '../assets/images/sidebar/elements.svg'
import { ReactComponent as Users } from '../assets/images/sidebar/users.svg'
import { ReactComponent as MyWork } from '../assets/images/sidebar/briefcase.svg'
import { ReactComponent as Payments } from '../assets/images/sidebar/card.svg'
import { ReactComponent as MyAgreements } from '../assets/images/sidebar/suitcase.svg'
import { FiFileText, FiHelpCircle, FiSettings, FiHelpCircle as Support } from 'react-icons/fi'
import { ReactComponent as Pin } from '../assets/images/pin.svg'

import { ReactComponent as Projects } from '../assets/images/sidebar/folder-user.svg'
import { ReactComponent as Reports } from '../assets/images/sidebar/chart.svg'
import { ReactComponent as POC } from '../assets/images/sidebar/user-tick.svg'
import { ReactComponent as Clients } from '../assets/images/building.svg'
import { ReactComponent as MessageSmile } from '../assets/images/sidebar/message-smile-circle.svg'
import { HiOutlineDocumentCheck as Agreements } from 'react-icons/hi2'
// import { ReactComponent as Support } from '../assets/images/sidebar/question-circle.svg'

export const PROVIDER_SIDEBAR_ITEMS = [
    // {
    //     to: '/dashboard',
    //     icon: Dashboard,
    //     label: 'Dashboard',
    //     paths: []
    // },
    {
        to: '/find-work',
        icon: Users,
        label: 'Find Work',
        paths: []
    },
    {
        to: '/my-work',
        icon: MyWork,
        label: 'My Work',
        paths: []
    },
    {
        to: '/payments',
        icon: Payments,
        label: 'Payments',
        paths: []
    },
    {
        to: '/calendar',
        icon: Users,
        label: 'Calendar',
        paths: []
    },
    {
        to: '/my-agreements',
        icon: MyAgreements,
        label: 'My Agreements',
        paths: []
    },
    {
        to: '/support',
        icon: Support,
        label: 'Support',
        paths: []
    },
]

export const BUYER_SIDEBAR_ITEMS = [
    {
        to: '/work-orders',
        icon: MyWork,
        label: 'Work Orders',
        paths: []
    },
    {
        to: '/providers',
        icon: Users,
        label: 'Providers',
        paths: []
    },
    {
        to: '/clients',
        icon: Clients,
        label: 'Clients',
        paths: []
    },
    {
        to: '/projects',
        icon: Projects,
        label: 'Projects',
        paths: []
    },
    {
        to: '/agreements',
        icon: Agreements,
        label: 'Agreements',
        paths: []
    },
    {
        to: '/payments',
        icon: Payments,
        label: 'Payments',
        paths: []
    },
    {
        to: '/reports',
        icon: Reports,
        label: 'Reports',
        paths: []
    },
    {
        to: '/poc',
        icon: POC,
        label: 'POC',
        paths: []
    },
    {
        to: '/support',
        icon: MessageSmile,
        label: 'Support',
        paths: []
    },
]

export const PROFILE_ITEMS = [
    // {
    //     to: '/profile',
    //     icon: FiHome,
    //     label: 'My Profile',
    //     paths: []
    // },
    {
        to: '/settings',
        icon: FiSettings,
        label: 'Settings',
        paths: []
    },
    {
        to: '/help',
        icon: FiHelpCircle,
        label: 'Help',
        paths: []
    },
    {
        to: '/legal',
        icon: FiFileText,
        label: 'Legal',
        paths: []
    },
    {
        to: '/privacy-policy',
        icon: Pin,
        label: 'Privacy Policy',
        paths: []
    },
]

export const BUYER_PROFILE_SIDEBAR_ITEMS = [
    {
        title: 'General',
        items: [
            { label: 'Company Profile', to: '/profile/company' },
            { label: 'Tax Information', to: '/profile/tax-information' },
            { label: 'Tax Documentation', to: '/profile/tax-documentation' }
        ]
    },
    {
        title: 'Payment Management',
        items: [
            { label: 'Payment Method', to: '/profile/payment-method' },
            { label: 'Spending Limit', to: '/profile/spending-limit' }
        ]
    },
    {
        title: 'User Management',
        items: [
            { label: 'Manage Members', to: '/profile/manage-members' },
        ]
    },
    {
        title: 'Company Settings',
        items: [
            { label: 'Roles and Permissions', to: '/profile/roles-permissions' },
            { label: 'Activity Log', to: '/profile/activity-log' },
            { label: 'Integrations', to: '/profile/integrations' },
        ]
    },
]

export const selectStyles = {
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.selectProps.value ? 'black' : '#667085',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    container: (provided) => ({
        ...provided,
        display: 'block',
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 500
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: '10px',
        borderColor: state.isFocused || state.menuIsOpen ? '#920AF2' : '#E2E8F0',
        padding: '10px',
        boxShadow: 'none',
        ':hover': {
            borderColor: state.menuIsOpen ? '#920AF2' : '#E2E8F0',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#94A3B8',
        fontWeight: 400
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#920AF2' : '#0000',
        color: state.isFocused ? 'white' : 'black',
        ':active': {
            backgroundColor: '#920AF2',
        }
    })
};

export const dashboardFilterDropdownStyles = {
    ...selectStyles,
    control: (provided, state) => ({
        ...provided,
        borderRadius: '10px',
        borderColor: state.isFocused || state.menuIsOpen ? '#920AF2' : '#E2E8F0',
        padding: '0 4px',
        minWidth: 150,
        boxShadow: 'none',
        ':hover': {
            borderColor: state.menuIsOpen ? '#920AF2' : '#E2E8F0',
        },
    }),
    container: (provided) => ({
        ...provided,
        color: '#25324B',
        fontSize: 14,
        fontWeight: 400,
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#667085'
    })
};