import { Col, Row, Stack } from 'react-bootstrap'
import { CardWrapper } from '../../common/Default'
import { ReactComponent as Business } from '../../../assets/images/business.svg'
import { HiDotsVertical, HiOutlineLocationMarker } from 'react-icons/hi'
import { Button } from '../../common/button/Button'
import { MdOutlineCalendarToday } from 'react-icons/md'

export const InvitesContent = () => {
    return (
        <Row className='row-gap-4'>
            <Col md={4}>
                <CardWrapper>
                    <Stack direction='horizontal' className='justify-content-between align-items-center mb-2'>
                        <Business />
                        <HiDotsVertical className='color-grey-500 cursor-pointer' />
                    </Stack>
                    <span className='color-grey-500 font-10 fw-medium'>#1233944</span>
                    <p className='font-14 fw-semibold'>
                        Work that create money that move the world and control ...
                    </p>
                    <Stack direction='horizontal' className='justify-content-between my-2'>
                        <div className='d-flex align-items-center color-grey-500 gap-1'>
                            <HiOutlineLocationMarker />
                            <span className='font-12 lh-1'>Birmingham, United Kingdom</span>
                        </div>
                        <span className='job-status'>ACTIVE</span>
                    </Stack>
                    <p className='font-12 color-grayscale-500'>
                        Water purification and drilling of borehole for a 5 bedroom duplex...
                    </p>
                    <Stack direction='horizontal' className='gap-1 w-fit'>
                        <h4 className='color-primary fw-bold my-3'>$3,500<span className='color-pale-sky fw-normal'>/m</span></h4>
                        <span className='rounded-pill card-tag__fixed'>FIXED</span>
                    </Stack>
                    <div className='d-flex align-items-center color-grey-500 gap-1 mb-1'>
                        <HiOutlineLocationMarker size={14} />
                        <span className='font-10 lh-1'>24, Amandos Avenue, Birmingham | 12 miles</span>
                    </div>
                    <div className='d-flex align-items-center color-grey-500 gap-1 mb-3'>
                        <MdOutlineCalendarToday size={14} />
                        <span className='font-10 lh-1'>Sun 24, Mar 2024 | 10:20AM</span>
                        <span className='font-10 lh-1'>-</span>
                        <span className='font-10 lh-1'>Sun 24, Mar 2024 | 10:20AM</span>
                    </div>
                    <Button className='w-100 py-3 btn__accept-invite color-primary'>Accept Invite</Button>
                </CardWrapper>
            </Col>
        </Row>
    )
}
