import { ResetPasswordWrapper } from '../../../../components/common/Default'
import { ReactComponent as Lock } from '../../../../assets/images/lock.svg'
import {
    // Button,
    Form
} from 'react-bootstrap'
import { Input } from '../../../../components/common/input/Input'
import { Button as MyButton } from '../../../../components/common/button/Button'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'

export const ForgotPassword = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!value) return setError('Email address is required');

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(value)) return setError('Please enter a valid email address');

        if (value) setError('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setError('');

        if (!email) return setError('Email address is required');

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(email)) return setError('Please enter a valid email address');

        var data = {}
        const formData = new FormData(e.target)
        formData.forEach((val, key) => data[key] = val)

        try {
            const response = await PostRequest('/provider/send-otp/email', data)
            setEmail('');
            navigate('/reset-password-otp', { state: { email, ...response } })
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    const isEmailValid = email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    return (
        <ResetPasswordWrapper>
            <div className='text-center'>
                <Lock className='mb-4' />
                <div className='mb-3'>
                    <h5 className='welcome-heading fs-3 line-height-125'>Reset your password</h5>
                    <p className='get-started fw-normal line-height-150 mx-auto' style={{ maxWidth: '350px' }}>Enter your email address and we'll send you password reset instructions.</p>
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <Input
                    label='Email address'
                    name='email'
                    id='email'
                    htmlFor='email'
                    autoComplete='email'
                    type='email'
                    value={email}
                    onChange={handleEmailChange}
                    error={error}
                    placeholder='Enter your email address'
                    className='mb-3'
                />
                <MyButton type='submit' disabled={!isEmailValid} className='w-100 mb-4'>Send Email</MyButton>
            </Form>
            {/* <div className='login-text line-height-160 color-secondary'>
                Didn't receive a code?  {' '}
                <Button variant='link' className='color-primary fs-inherit fw-inherit p-0'>Resend</Button>
            </div> */}
        </ResetPasswordWrapper>
    )
}
