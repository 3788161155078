import { applyMiddleware, combineReducers, createStore } from 'redux';
import { providerReducer } from './provider/provider.reducer';
import { thunk } from 'redux-thunk';
import { buyerReducer } from './buyer/buyer.reducer';

const rootReducer = combineReducers({
    providerReducer: providerReducer,
    buyerReducer: buyerReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store