import { Form } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'
import { useEffect, useState } from 'react'
import { AuthWrapper } from '../../../../components/common/Default'
import { useLocation } from 'react-router-dom'
import { getAuthProvider, showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'
import { MyOTPInput } from '../../../../components/common/my-otp-input/MyOTPInput'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProviderDetails } from '../../../../store/provider/provider.action'

export const EmailOTPVerification = () => {

  const location = useLocation()
  const dispatch = useDispatch()

  const providerDetails = useSelector(state => state.providerReducer.providerDetails)
  const { authData: authProvider } = getAuthProvider()

  const [otp, setOtp] = useState();
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault()

    setError('');

    if (!otp) return setError('OTP is required');

    if (otp?.length === 6) {
      try {
        await PostRequest('/provider/verify-otp/email', { otp: otp, ...location?.state })
        if (authProvider) {
          window.localStorage.removeItem('AUTH_PROVIDER')
        }
        window.localStorage.setItem('AUTH_PROVIDER', JSON.stringify(providerDetails));
        if (providerDetails?.isProfileCompleted) {
          window.location.href = '/dashboard'
        } else {
          window.location.href = '/get-started'
        }
      } catch (error) {
        console.log('error', error)
        showToaster(error.message, 'error')
      }
    } else {
      return setError('Please enter a valid 6-digit OTP')
    }
  }

  useEffect(() => {
    dispatch(fetchProviderDetails())
  }, [])

  return (
    <AuthWrapper>
      <h5 className='welcome-heading'>Confirm Identity</h5>
      <p className='text-black mb-4'>Enter the code sent to your email to confirm your identity</p>
      <Form onSubmit={handleSubmit}>
        <MyOTPInput
          value={otp}
          onChange={setOtp}
          inputType='number'
          label='Enter OTP'
          error={error}
          wrapperClasses='hide__arrows'
        />
        <Button type='submit' className='w-100 mb-3'>Verify</Button>
      </Form>
      <Form.Check
        type='checkbox'
        label='Always remember this device'
        name='remember'
        id='remember-device'
      />
    </AuthWrapper>
  )
}
