// import { FcGoogle } from 'react-icons/fc'
import './sign-up.css'
import { Form } from 'react-bootstrap'
import { FaApple } from 'react-icons/fa'
import { Input } from '../../../../components/common/input/Input'
import { Button } from '../../../../components/common/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { AuthWrapper } from '../../../../components/common/Default'
// import { useGoogleLogin } from '@react-oauth/google'
import { showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'
import { GoogleLogin } from '@react-oauth/google'
import { useEmailValidation } from '../../../../hooks/useEmailValidation'

export const WelcomeToZero = () => {

    const providerEmail = window.localStorage.getItem('PROVIDER_EMAIL') || ''

    const navigate = useNavigate()

    const { email, error, isEmailValid, handleEmailChange, handleSubmit } = useEmailValidation({
        initialEmail: providerEmail
    })

    const handleFormSubmit = async (e) => {

        const formResult = handleSubmit(e)
        if(!formResult) return
        
        const { data, email: formEmail } = formResult
        if (data && formEmail) {
            
            try {
                const response = await PostRequest('/provider/email/availability', { email }, { showLoader: 'check-email-button' })
                console.log('response', response)
                if (providerEmail) {
                    window.localStorage.removeItem('PROVIDER_EMAIL')
                }
                window.localStorage.setItem('PROVIDER_EMAIL', email)
    
                navigate('/account-setup', { state: email })
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    const handleLoginWithGoogle = async (resp) => {
        // console.log('resp', resp)
        try {
            const response = await PostRequest('/provider/login/google', { token: resp.credential })

            // console.log('response', response)
            if (window.localStorage.getItem('TOKEN')) {
                window.localStorage.removeItem('TOKEN')
            }
            window.localStorage.setItem('TOKEN', response?.token);
            navigate('/dashboard')
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    // const login = useGoogleLogin({
    //     onSuccess: (response) => {
    //         handleLoginWithGoogle(response)
    //     },
    //     onError: () => {
    //         console.log('Login Failed')
    //     }
    // })

    return (
        <AuthWrapper>
            <div className='text-center'>
                <p className='get-started line-height-125 color-grey-500'>GET STARTED</p>
                <h5 className='welcome-heading line-height-125 mb-3'>Welcome to WorkRobin</h5>
            </div>
            {/* <button
                className='button button__google'
            onClick={login}
            >
                <FcGoogle size={22} />
                <span className='line-height-150'>Sign up with Google</span>
            </button> */}
            <GoogleLogin
                onSuccess={(resp) => handleLoginWithGoogle(resp)}
                onError={() => console.log('Login Failed')}
                logo_alignment='center'
                text='signup_with'
                containerProps={{ style: { height: 48 }, className: 'google-login-button' }}
                useOneTap
            />
            <button className='button button__apple mt-3'>
                <FaApple size={22} />
                <span className='line-height-150'>Sign up with Apple</span>
            </button>
            <div className='or-register-with-container'>
                <hr className='left-line' />
                <span className='or-text line-height-160'>Or register with</span>
                <hr className='right-line' />
            </div>
            <Form onSubmit={handleFormSubmit}>
                <Input
                    label='Email Address'
                    name='email'
                    id='email'
                    htmlFor='email'
                    type='email'
                    autoComplete='email'
                    placeholder='example@company.com'
                    value={email}
                    onChange={handleEmailChange}
                    error={error}
                    required
                    className='mb-3'
                />
                <Button type='submit' id='check-email-button' disabled={!isEmailValid} className='w-100 mb-3'>Continue with email</Button>
            </Form>
            <div className='login-text line-height-160 color-secondary'>
                Already have an account? {' '}
                <Link to='/login' className='text-decoration-none color-primary'>Login Here</Link>
            </div>
        </AuthWrapper>
    )
}
