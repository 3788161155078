import { Container, Form, Nav, Navbar } from 'react-bootstrap'
import './header.css'
import { FiHelpCircle } from 'react-icons/fi'
import { MessageDropdown, NotificationDropdown, ProfileDropdown, WalletDropdown } from './nav-dropdown'

export const Header = () => {

  return (
    <Navbar expand='lg' className='bg-white border-bottom sticky-top'>
      <Container fluid>
        <Navbar.Brand>
          <Form.Control
            type='text'
            placeholder='Search'
            className='form-control__search'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto align-items-center'>
            <WalletDropdown />
            <div className='vr align-self-center' style={{ height: 40 }} />
            <Nav.Link href='#link'><FiHelpCircle size={20} color='#667185' /></Nav.Link>
            <MessageDropdown />
            <NotificationDropdown />
            <ProfileDropdown />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
