import React from 'react';
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigates to the previous page
    };

    return (
        <div style={styles.container}>
            <img 
                src={'/circular_logo.png'} 
                alt="Logo" 
                style={styles.logo} 
            />
            <h1 style={styles.heading}>Coming Soon</h1>
            <button onClick={goBack} style={styles.backButton}>Go Back</button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
    },
    logo: {
        width: '150px',
        marginBottom: '20px',
    },
    heading: {
        fontSize: '2.5rem',
        marginBottom: '20px',
        color: '#333',
    },
    backButton: {
        padding: '10px 20px',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default ComingSoon;