import { Badge, Nav, Tab } from 'react-bootstrap'
import { HiArrowRight } from 'react-icons/hi'
import './job-updates.css'
import { TbLayoutCards, TbLayoutList } from 'react-icons/tb'
import { useState } from 'react'

export const JobUpdates = ({ navItems, tabPanes }) => {

  const [isGridLayout, setIsGridLayout] = useState(true)

  const toggleLayout = () => setIsGridLayout(prev => !prev)

  return (
    <>
      <div className='top-nav d-flex justify-content-between py-3 mt-3'>
        <h4 className='mb-0 fs-5'>Work Orders</h4>
        <button className='color-primary border-0 bg-transparent'>
          <span className='fw-bold'>View All</span>
          <HiArrowRight size={24} className='ms-2' />
        </button>
      </div>
      <Tab.Container defaultActiveKey={navItems[0]?.eventKey || ''}>
        <Nav
          variant='tabs'
          className='my-3 work-order__tabs fw-bold'
        >
          {navItems?.map(({ eventKey, label, badge }) => (
            <Nav.Item key={eventKey}>
              <Nav.Link eventKey={eventKey} as='button'>
                <span>{label}</span>
                {badge && <Badge className='ms-2 rounded-circle py-1'>{badge}</Badge>}
              </Nav.Link>
            </Nav.Item>
          ))}
          <div className='ms-auto d-flex gap-3 pb-1'>
            <div
              className={`layout_wrapper ${isGridLayout ? 'layout_active' : 'layout_inactive'}`}
              onClick={toggleLayout}
            >
              <TbLayoutCards />
            </div>
            <div
              className={`layout_wrapper ${!isGridLayout ? 'layout_active' : 'layout_inactive'}`}
              onClick={toggleLayout}
            >
              <TbLayoutList />
            </div>
          </div>
        </Nav>
        <Tab.Content>
          {tabPanes?.map(({ eventKey, Content }) => (
            <Tab.Pane eventKey={eventKey} key={eventKey}>
              <Content />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </>
  )
}
