import { Col, Form, Row } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Button } from '../../../../components/common/button/Button'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { Link, useNavigate } from 'react-router-dom'
import { getAuthProvider, showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../../../config/API_URL'
import { fetchProviderDetails } from '../../../../store/provider/provider.action'

const INFO = {
    title: 'Your photo and details',
    description: 'Getting your account more personalized'
}

export const PhotoAndLocation = () => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    const { parsedAuthData: parsedAuthProvider } = getAuthProvider()

    const [formData, setFormData] = useState({
        imageSrc: null,
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: ''
    })

    const [errors, setErrors] = useState({
        imageSrc: '',
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: ''
    });

    const fileInputRef = useRef(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prev => ({
                    ...prev,
                    imageSrc: reader.result
                }))
            }
            reader.readAsDataURL(file)
        }
    };

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName' && !value) {
            error = 'First name is required';
        } else if (name === 'middleName' && !value) {
            error = 'Middle name is required';
        } else if (name === 'lastName' && !value) {
            error = 'Last name is required';
        } else if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        var updatedValue = value;
        if (name === 'phoneNumber') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '')
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            // imageSrc: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNumber: '',
        }

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.middleName) newErrors.businessName = 'Middle name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        // if (!formData.businessEmail) newErrors.businessEmail = 'Business Email is required';

        if (Object.values(newErrors).every(err => err === '')) {
            var data = {}
            var formDataObj = new FormData(e.target)
            formDataObj.forEach((val, key) => data[key] = val)

            try {
                await PostRequest('/provider/profile/update', data, { isMultiMedia: true, showLoader: 'photo-details' })
                navigate('/resume-uploader')
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if(parsedAuthProvider || providerDetails) {
            setFormData({
                imageSrc: providerDetails?.profileImage ? `${BASE_URL}/${providerDetails?.profileImage}` : null,
                firstName: parsedAuthProvider?.firstName || providerDetails?.firstName || '',
                middleName: providerDetails?.middleName || '',
                lastName: parsedAuthProvider?.lastName || providerDetails?.lastName || '',
                phoneNumber: providerDetails?.phoneNumber || '',
            })
        }
    }, [providerDetails])

    useEffect(() => {
        dispatch(fetchProviderDetails())
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value)

    return (
        <ProfileWrapper isProvider email info={INFO}>
            <Form onSubmit={handleSubmit}>
                <div className='text-center mb-2'>
                    {formData?.imageSrc ? (
                        <img
                            src={formData?.imageSrc}
                            alt='Uploaded'
                            className='mb-2 rounded-circle object-fit-cover'
                            style={{ height: 96, width: 96 }}
                        />
                    ) : (
                        <UserCircle className='mb-2' />
                    )}

                    <Button
                        variant='outline'
                        onClick={(e) => {
                            e.preventDefault()
                            fileInputRef.current.click()
                        }}
                        className='d-block mx-auto py-2 rounded fw-medium'
                    >
                        {formData?.imageSrc ? 'Edit picture' : 'Upload new picture'}
                    </Button>
                    <input
                        type='file'
                        ref={fileInputRef}
                        name='profileImage'
                        className='d-none'
                        accept='image/png, image/jpeg'
                        onChange={handleFileChange}
                    />
                </div>

                <Input
                    label='First name'
                    placeholder='First name'
                    htmlFor='firstName'
                    id='firstName'
                    name='firstName'
                    value={formData?.firstName}
                    onChange={handleChange}
                    className='mb-2'
                />
                <Input
                    label='Middle name'
                    placeholder='Middle name'
                    name='middleName'
                    htmlFor='middleName'
                    id='middleName'
                    value={formData?.middleName}
                    onChange={handleChange}
                    className='mb-2'
                />
                <Input
                    label='Last name'
                    placeholder='Last name'
                    name='lastName'
                    htmlFor='lastName'
                    id='lastName'
                    value={formData?.lastName}
                    onChange={handleChange}
                    className='mb-2'
                />
                <Input
                    label='Phone Number'
                    name='phoneNumber'
                    htmlFor='phoneNumber'
                    id='phoneNumber'
                    placeholder='01-1199929292'
                    value={formData?.phoneNumber}
                    onChange={handleChange}
                    className='mb-3'
                />

                <Row className='mb-1 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Link
                            to='/tax-information'
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony py-3 text-center'
                        >
                            Back
                        </Link>
                    </Col>
                    <Col><Button type='submit' id='photo-details' disabled={!isFormValid()} className='w-100 py-3'>Continue</Button></Col>
                </Row>
            </Form>
        </ProfileWrapper>
    )
}
