import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Button } from '../../../../components/common/button/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Select } from '../../../../components/common/select/Select'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'
import { fetchProviderDetails, fetchSkills } from '../../../../store/provider/provider.action'

const INFO = {
    title: <>What work are you <br /> here to do?</>,
    description: 'Your skills show clients what you can offer'
}

const customStyles = {
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#F8F8FD',
        padding: '4px',
        gap: 4
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#920AF2',
        padding: 0,
        paddingLeft: 0
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#920AF2',
    }),
    menu: (provided) => ({
        ...provided,
        boxShadow: '5px 5px 50px 0px rgba(26, 32, 44, 0.06)'
    }),
    menuList: (provided) => ({
        ...provided,
        scrollbarWidth: 'none'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#0000',
        color: state.isFocused ? 'black' : '#667185',
        ':active': {
            backgroundColor: '#0000', // '#920AF2'
        }
    })
}

export const SkillsInputSection = () => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    var SKILLS = useSelector(state => state.providerReducer.skills)
    SKILLS = SKILLS?.map(option => ({
        label: option?.skill,
        value: option?.skill
    }))

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const { email } = location.state || {}

    const [selectedSkills, setSelectedSkills] = useState([])
    const [skillError, setSkillError] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        setSkillError('');

        if (selectedSkills.length === 0) return setSkillError('Skills is required')

        var data = {}
        var formData = new FormData(e.target)
        const skillsArray = []
        formData.forEach((val, _) => skillsArray.push(val))
        data['skills'] = JSON.stringify(skillsArray) || '[]'

        try {
            await PostRequest('/provider/profile/update', data, { showLoader: 'skills-button' })
            navigate('/bio', { state: location?.state })
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        if (providerDetails?.skills?.length) {
            const initialSelectedSkills = providerDetails.skills.map(skill => ({
                label: skill,
                value: skill
            }))    
            setSelectedSkills(initialSelectedSkills);
        }
    }, [providerDetails]);

    useEffect(() => {
        dispatch(fetchProviderDetails())
        dispatch(fetchSkills())
    }, [])

    return (
        <ProfileWrapper isProvider email={email} info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Select
                    label='Your skills'
                    name='skills'
                    inputId='skills-select'
                    htmlFor='skills-select'
                    placeholder='Start typing to search for skills'
                    options={SKILLS}
                    value={selectedSkills}
                    onChange={(selectedOptions) => setSelectedSkills(selectedOptions)}
                    className='mb-3'
                    isMulti
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    newStyles={customStyles}
                    newControlStyle={{ cursor: 'text', paddingBlock: 14 }}
                    error={skillError}
                />
                {selectedSkills?.length === 0 ? (
                    <Button type='submit' disabled className='w-100 py-3'>Now, pitch yourself</Button>
                ) : (
                    <Stack direction='horizontal' gap={4}>
                        <Link
                            to='/resume-uploader'
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony text-center py-3'
                        >
                            Back
                        </Link>
                        <Button type='submit' id='skills-button' className='w-100 py-3'>Continue</Button>
                    </Stack>
                )}
            </Form>
        </ProfileWrapper>
    )
}
