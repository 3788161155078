import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as EmailBox } from '../../../../assets/images/email-icon.svg'
import { Link, useLocation } from 'react-router-dom'

export const ConfirmationMail = () => {

    const location = useLocation();

    const { email, ...rest } = location?.state || {}

    return (
        <Container>
            <Row className='justify-content-center align-items-center vh-100'>
                <Col xs={11} sm={8} md={4} className='text-center'>
                    <EmailBox className='mb-5' />
                    <h5 className='welcome-heading fs-3 fw-bold line-height-125'>Email sent</h5>
                    <p className='mb-3'>
                        We've sent a link to create a new password to {email}. If it's not in your inbox, check your spam/junk folder.
                    </p>
                    <Link to='/reset-password-otp' state={rest} className='custom_button zero_button d-block text-decoration-none line-height-150 w-100 mb-4'>Continue</Link>
                    <div className='login-text line-height-160 text-black'>
                        Don't have an account? {' '}
                        <Link to='/provider/welcome' className='text-decoration-none color-primary'>Sign Up</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
